import React from "react";
import { StyledSectionHeaderContainer } from "./sectionheader.styles";

export const SectionHeader = ({
  title,
  style,
}: {
  title: string;
  style?: React.CSSProperties;
}) => {
  return (
    <StyledSectionHeaderContainer style={style}>
      <h2>{title}</h2>
    </StyledSectionHeaderContainer>
  );
};
