import React from "react";
import { StyledInputContainer } from "./input.styles";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  label?: string;
}

export const Input = ({ error, label, ...props }: IProps) => {
  return (
    <StyledInputContainer error={error} className={props.className}>
      <p>{label}</p>
      <input {...props} />
      <small>{error}</small>
    </StyledInputContainer>
  );
};
