export const getUserTimeZone = (
  callback: ({
    currentTime,
    timeZone,
  }: {
    currentTime: string;
    timeZone: string;
  }) => void
) => {
  const updateUserTime = () => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const currentTime = new Date().toLocaleString("en-US", {
      timeZone: userTimezone,
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });

    callback({ currentTime, timeZone: userTimezone });
  };

  // Update every second (1000 milliseconds)
  setInterval(updateUserTime, 1000);
};
