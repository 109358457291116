import { register } from "@/app/store";
import { Button } from "@/components/Button";
import { Input } from "@/components/Input";
import { Modal } from "@/components/Modal";
import { useAppDispatch } from "@/hooks";
import { useRegister } from "@/network/hooks";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  LoginModalContainer,
  StyledAuthModalBottom,
  StyledErrorText,
  StyledRedirectAuth,
} from "./auth.styles";

export const CreateAccountModal = ({
  isOpen,
  onClose,
  onOpenLoginModal,
}: {
  isOpen: boolean;
  onClose: () => void;
  onOpenLoginModal: () => void;
}) => {
  const handleOpenLoginModal = () => {
    onClose();
    onOpenLoginModal();
  };
  const [error, setErrors]: any = useState({});

  const { dirty, errors, handleChange, isValid, values } = useRegister();

  const dispatch = useAppDispatch();
  const loading = useSelector((state: any) => state.user.loading);

  const submit = async (e: any) => {
    e.preventDefault();
    if (!validatePassword()) {
      const result = await dispatch(register(values));
      if (result) {
        onClose();
      }
    }
  };

  const validatePassword = () => {
    const newErrors = {
      length: "",
      uppercase: "",
      lowercase: "",
      number: "",
      specialChar: "",
    };
    if (values.password?.length < 8) {
      newErrors.length = "Password must be at least 8 characters long.";
    }
    if (!/[A-Z]/.test(values?.password)) {
      newErrors.uppercase =
        "Password must contain at least one uppercase letter.";
    }
    if (!/[a-z]/.test(values.password)) {
      newErrors.lowercase =
        "Password must contain at least one lowercase letter.";
    }
    if (!/[0-9]/.test(values.password)) {
      newErrors.number = "Password must contain at least one number.";
    }
    if (!/[^A-Za-z0-9]/.test(values.password)) {
      newErrors.specialChar =
        "Password must contain at least one special character.";
    }
    setErrors(newErrors);
    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <LoginModalContainer>
        <h1>Create Account</h1>
        <Input
          label="UserName"
          placeholder="Enter your UserName"
          type="text"
          name="username"
          value={values.username}
          onChange={handleChange}
          error={errors?.username}
        />
        <Input
          label="Email"
          placeholder="Enter your Email address"
          type="email"
          name="email"
          value={values.email}
          onChange={handleChange}
          error={errors?.email}
        />
        <Input
          label="password"
          placeholder="Enter your password"
          type="password"
          name="password"
          value={values.password}
          onChange={handleChange}
          error={errors?.password}
        />
        {Object.keys(error).length > 0 && (
          <ul>
            {Object.keys(error).map((errorKey) => (
              <StyledErrorText key={errorKey}>
                {error[errorKey]}
              </StyledErrorText>
            ))}
          </ul>
        )}
        <Input
          label="Confirm Password"
          placeholder="Re-Enter your password"
          type="password"
          name="password_confirmation"
          value={values.password_confirmation}
          onChange={handleChange}
          error={errors?.password_confirmation}
        />
        <Button
          label="Create Account"
          isLoading={loading}
          disabled={!isValid || !dirty}
          onClick={(e) => submit(e)}
        />
        <StyledAuthModalBottom>
          <StyledRedirectAuth />
          <StyledRedirectAuth>
            <span>
              Already have an account?{" "}
              <span onClick={handleOpenLoginModal}>Login</span>
            </span>
          </StyledRedirectAuth>
        </StyledAuthModalBottom>
      </LoginModalContainer>
    </Modal>
  );
};
