import {
  StyledDateItem,
  StyledDatesContainer,
  StyledGameTypeHeaderContaner,
  StyledSportItem,
  StyledSportsContainer,
  StyledSubheaderContainer,
} from "./gameTypeHeader.styles";

export const GameTypeHeader = ({ showGames }: { showGames: boolean }) => {
  return (
    <StyledSubheaderContainer>
      {showGames ? (
        <StyledGameTypeHeaderContaner>
          <StyledSportsContainer>
            <StyledSportItem to="?football" isactive="true">
              Football
            </StyledSportItem>
            <StyledSportItem to="?football" isactive="false">
              Basketball
            </StyledSportItem>
            <StyledSportItem to="?football" isactive="false">
              Rugby
            </StyledSportItem>
            <StyledSportItem to="?football" isactive="false">
              Tennis
            </StyledSportItem>
            <StyledSportItem to="?football" isactive="false">
              Cricket
            </StyledSportItem>
            <StyledSportItem to="?football" isactive="false">
              Boxing
            </StyledSportItem>
            <StyledSportItem to="?football" isactive="false">
              Baseball
            </StyledSportItem>
            <StyledSportItem to="?football" isactive="false">
              handball
            </StyledSportItem>
          </StyledSportsContainer>
        </StyledGameTypeHeaderContaner>
      ) : null}
      <StyledDatesContainer>
        <StyledDateItem isactive="false">Apr 12</StyledDateItem>
        <StyledDateItem isactive="false">Apr 13</StyledDateItem>
        <StyledDateItem isactive="false">Apr 14</StyledDateItem>
        <StyledDateItem isactive="true">today</StyledDateItem>
        <StyledDateItem isactive="false">Apr 16</StyledDateItem>
        <StyledDateItem isactive="false">Apr 17</StyledDateItem>
        <StyledDateItem isactive="false">Apr 18</StyledDateItem>
      </StyledDatesContainer>
    </StyledSubheaderContainer>
  );
};
