import styled from "styled-components";

export const StyledEmptyStateContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledEmptyStateContent = styled.div`
  width: 60%;
  background: ${({ theme }) => theme.palette.container};
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 0px;
  text-align: center;
  .title {
    font-size: 1.3rem;
    color: ${({ theme }) => theme.palette.primary};
  }
  .sub {
    font-size: 0.9rem;
    color: ${({ theme }) => theme.palette.grey};
    margin-top: 1rem;
  }
`;
