import { updateUser, updateUserEmail } from "@/app/store";
import { useAppDispatch } from "@/hooks";
import {
  BaseError,
  Endpoints,
  LoginUserDTO,
  LoginUserResponse,
  RegisterUserDTO,
  RegisterUserResponse
} from "@/network/interface";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { httpClient } from "../api";

export const useLogin = ({

  handleCloseModal,
}:
 {
  handleCloseModal?: () => void;
}) => {
  const dispatch = useAppDispatch();

  const { mutate, isLoading } = useMutation(
    async (values: LoginUserDTO) => {
      console.log(values)
      const res = await httpClient.post<LoginUserResponse>(
        Endpoints.LOGIN,
        values
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        console.log(data)
        toast.success(`Welcome back, ${data?.data?.username}`);
        dispatch(updateUser(data?.data));
        handleCloseModal?.();
      },
      onError: (error: BaseError) => {
        console.log(error.response)
        const message = error?.response?.data?.message;
        const email_error = error?.response?.data?.errors["email"][0];
        const password_error = error?.response?.data?.errors["password"][0];
        setFieldError("password", password_error);
        setFieldError("email", email_error);
        toast.error(message);
      },
    }
  );

  const onSubmit = (values: LoginUserDTO) => {
    mutate(values);
  };

  const initialValues: LoginUserDTO = {
    email: "",
    password: "",
  };

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Please provide a valid email address!.")
      .required("Please provide your email address!."),
    password: yup.string().required("Please provide a your account password!."),
  });

  const {
    handleChange,
    dirty,
    errors,
    isValid,
    handleSubmit,
    values,
    setFieldError,
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: schema,
  });

  return {
    isLoading,
    handleChange,
    dirty,
    errors,
    isValid,
    handleSubmit,
    values,
  };
};

export const useRegister = () => {
  
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { mutate, isLoading } = useMutation(
    async (values: RegisterUserDTO) => {
      console.log(values)
      const res = await httpClient.post<RegisterUserResponse>(
        Endpoints.REGISTER,
        values
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        console.log(data)
        toast.success(`Welcome back, ${data?.email}`);
        dispatch(updateUserEmail({ email: data?.email }));
        navigate("/");
      },
      onError: (error: BaseError) => {
        console.log(error?.response)


        const message = error?.response?.data?.message;
        const username_error = error?.response?.data?.errors["username"][0];
        const email_error = error?.response?.data?.errors["email"][0];
        const password_error = error?.response?.data?.errors["password"][0];
        const password_confirmation_error =
          error?.response?.data?.errors["password_confirmation"][0];
        setFieldError("username", username_error);
        setFieldError("email", email_error);
        setFieldError("password", password_error);
        setFieldError("password_confirmation", password_confirmation_error);
        toast.error(message);
      },
    }
  );

  const onSubmit = (values: RegisterUserDTO) => {
    mutate(values);
  };

  const initialValues: RegisterUserDTO = {
    email: "",
    password: "",
    password_confirmation: "",
    username: "",
  };

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Please provide a valid email address!.")
      .required("Please provide your email address!."),
    password: yup
      .string()
      .min(5, "Password must be atleast 5 character.")
      .required("Please provide a your account password!."),
    username: yup.string().required("Please provide your username!."),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match")
      .required("Please re-enter your account password!."),
  });

  const {
    handleChange,
    dirty,
    errors,
    isValid,
    handleSubmit,
    values,
    setFieldError,
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: schema,
  });

  return {
    isLoading,
    handleChange,
    dirty,
    errors,
    isValid,
    handleSubmit,
    values,
  };
};
