import { Button } from "@/components";
import { AiOutlineArrowDown } from "react-icons/ai";
import {
  StyledSubscriptionGames,
  StyledSubscriptionGamesContainer,
  StyledSubscriptionGamesContainerHeader,
  StyledSubscriptionItem,
  StyledSubscriptionItemWrapper,
  StyledSubscriptionPageContainer,
  StyledSubscriptionTitle,
  StyledSubscriptionsContainer,
} from "./subscription.styles";

export const SubscriptionPage = () => {
  const subscriptions = [
    {
      title: "Basic",
      btn_title: "Free",
      access: [
        {
          title: "Games",
          dropdown: true,
          background: true,
          subs: [
            { title: "2 Odds Banker" },
            { title: "3 Odds Banker" },
            { title: "5 Odds Banker" },
            { title: "Straight Win" },
            { title: "Double Chance" },
            { title: "Over/Under" },
          ],
        },
        {
          title: "Access to Basic tips",
          dropdown: false,
          background: false,
          subs: [{ title: "Help Access (Email)" }],
        },
      ],
    },
    {
      title: "Experts (RECOMMENDED)",
      btn_title: "Pay",
      access: [
        {
          title: "Games",
          dropdown: true,
          background: true,
          subs: [
            { title: "2 Odds Banker" },
            { title: "3 Odds Banker" },
            { title: "5 Odds Banker" },
            { title: "Straight Win" },
            { title: "Double Chance" },
            { title: "Over/Under" },
            { title: "Both Teams to score" },
            { title: "Draw" },
          ],
        },
        {
          title: "Access to Basic tips",
          dropdown: false,
          background: false,
          subs: [{ title: "SMS and EMail Notification" }],
        },
        {
          title: "Limited Risk Management",
          dropdown: false,
          background: false,
          subs: [{ title: "3 Sets of Expert Picks Daily (2-5 Odds)" }],
        },
        {
          title: "Help Access (Email)",
          dropdown: false,
          background: false,
          subs: [],
        },
      ],
    },
    {
      title: "Premium",
      btn_title: "Pay",
      access: [
        {
          title: "Games",
          dropdown: true,
          background: true,
          subs: [
            { title: "2 Odds Banker" },
            { title: "3 Odds Banker" },
            { title: "5 Odds Banker" },
            { title: "Straight Win" },
            { title: "Double Chance" },
            { title: "Over/Under" },
            { title: "Both Teams to score" },
            { title: "Draw" },
            { title: "Corrent Score" },
            { title: "Players to Score" },
            { title: "Mega Weekends" },
          ],
        },
        {
          title: "Access to Basic tips",
          dropdown: false,
          background: false,
          subs: [{ title: "SMS and EMail Notification" }],
        },
        {
          title: "Total Risk Management",
          dropdown: false,
          background: false,
          subs: [{ title: "3 Sets of Expert Picks Daily" }],
        },
        {
          title: "1 Sure Set of Super Daily Pic",
          dropdown: false,
          background: false,
          subs: [{ title: "Help Access (Email + Online Chat)" }],
        },
        {
          title: "Feedback Management Tool",
          dropdown: false,
          background: false,
          subs: [],
        },
      ],
    },
  ];

  return (
    <StyledSubscriptionPageContainer>
      <StyledSubscriptionTitle>Subscription Plans</StyledSubscriptionTitle>
      <StyledSubscriptionsContainer>
        {subscriptions?.map((sub, _idx) => (
          <StyledSubscriptionItem key={_idx}>
            <h2>{sub.title}</h2>
            <StyledSubscriptionItemWrapper>
              {sub?.access?.map((acc, _idx) => (
                <StyledSubscriptionGamesContainer key={_idx}>
                  <StyledSubscriptionGamesContainerHeader>
                    <p>{acc?.title}</p>
                    {acc?.dropdown ? <AiOutlineArrowDown /> : null}
                  </StyledSubscriptionGamesContainerHeader>
                  <StyledSubscriptionGames
                    withBg={acc?.background ? "true" : "false"}
                  >
                    {acc?.subs?.map((sub, _idx) => (
                      <p key={_idx}>{sub?.title}</p>
                    ))}
                  </StyledSubscriptionGames>
                </StyledSubscriptionGamesContainer>
              ))}
              <Button label={sub?.btn_title} green />
            </StyledSubscriptionItemWrapper>
          </StyledSubscriptionItem>
        ))}
      </StyledSubscriptionsContainer>
    </StyledSubscriptionPageContainer>
  );
};
