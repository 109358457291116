import { openAuthModal } from "@/app/store";
import { BarcaLogo, LivePlayers } from "@/assets";
import { EmptyState, SectionHeader } from "@/components";
import LoadingSpinner, { PageLoader } from "@/components/Spinner";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { useGetAllBettingCodes, useVoteBettingCode } from "@/network/hooks";
import { projects } from "@/utils";
import { useState } from "react";
import { FaLock, FaShareAlt, FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import {
  StyledLayoutCoinsContainer,
  StyledLayoutLeftContent,
  StyledLayoutRightContent,
  StyledLoadMoreButton,
  StyledTopMatchContainer,
  StyledTopMatchHeaderContainer,
  StyledVersusContainer,
  StyledVersusItem,
  StyledVersusText,
} from "../../layout/layout.styles";
import {
  StyledCodeItem,
  StyledCodeLeft,
  StyledCodePredictionCode,
  StyledCodeRatingActionContainer,
  StyledCodeRatingContainer,
  StyledCodeRatingItem,
  StyledCodeRatingTotal,
  StyledCodeRight,
  StyledCodeTableContainer,
} from "../Home/home.styles";
import {
  StyledBettingCodeNavigationItem,
  StyledBettingCodePageContainer,
  StyledBettingCodeSection,
  StyledBettingCodeSectionHeaderContainer,
  StyledCodeLogo,
} from "./bettingCodes.styles";

export const BettingCodePage = () => {
  const [selectedTab, setSelectedTab] = useState("BASIC");
  const { bettingCodes, isLoading: isLoadingBettingCodes } =
    useGetAllBettingCodes();
  const { isLoading: isVoting, vote, clicked } = useVoteBettingCode();

  const { access_token, user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const [current] = useState(0);

  // const length = projects.length;

  const handleTabChange = (tab: string) => {
    if (!access_token && tab !== "BASIC") {
      dispatch(openAuthModal());
    } else if (!user.isPremium && tab !== "BASIC") {
      alert("you do not have any active subscription to access this!.");
    } else {
      setSelectedTab(tab);
    }
  };
  console.log(bettingCodes, 66);
  return (
    <StyledBettingCodePageContainer>
      <div className="full  ">
        <div className="  mx-auto flex justify-between rounded-md w-[full] ">
          {/* <div className="  w-[24%]  rounded-md">
            <img
              src={
                current === 0
                  ? projects[3]?.image
                  : projects[current - 1]?.image
              }
              alt=""
              className="w-[100%] mx-auto h-full object-cover rounded-md opacity-"
            />
          </div> */}
          {projects?.map((slide, index) => {
            return (
              <img
                src={slide.image}
                alt=""
                key={index}
                className={
                  index === current
                    ? "w-[60%] mx-auto  object-cover rounded-md transition-all duration-500"
                    : "hidden"
                }
              />
            );
          })}
          {/* <div className="  w-[24%] rounded-md ">
            <img
              src={
                current === 3
                  ? projects[0]?.image
                  : projects[current + 1]?.image
              }
              alt=""
              className="w-[100%] mx-auto h-full object-contain md:object-cover rounded-md opacity-"
            />
          </div> */}
        </div>
      </div>
      <div className="flex w-[98%] mx-auto ">
        <StyledLayoutLeftContent>
          <StyledLayoutCoinsContainer />
          <StyledTopMatchContainer>
            <StyledTopMatchHeaderContainer>
              <p>Top Match</p>
              <p>04 Jan 15:30</p>
            </StyledTopMatchHeaderContainer>
            <h1>BUNDESLIGA</h1>
            <StyledVersusContainer>
              <StyledVersusItem className="left">
                <img src={BarcaLogo} alt="team" />
                <p>Kano Rangers</p>
                <h3>0.52</h3>
              </StyledVersusItem>
              <StyledVersusText>VS</StyledVersusText>
              <StyledVersusItem className="right">
                <img src={BarcaLogo} alt="team" />
                <p>FC Barcelona</p>
                <h3>0.52</h3>
              </StyledVersusItem>
            </StyledVersusContainer>
            <StyledLoadMoreButton>
              <p>Load More</p>
            </StyledLoadMoreButton>
          </StyledTopMatchContainer>
        </StyledLayoutLeftContent>
        <main className=" w-[95%] md:w-[65%] mx-auto mt-[2rem]">
          <SectionHeader title="All Bookies" />
          <StyledBettingCodeSectionHeaderContainer>
            <StyledBettingCodeNavigationItem
              onClick={() => handleTabChange("BASIC")}
              isactive={selectedTab === "BASIC" ? "true" : "false"}
            >
              <span>Basic</span>
            </StyledBettingCodeNavigationItem>
            <StyledBettingCodeNavigationItem
              onClick={() => handleTabChange("EXPERT")}
              isactive={selectedTab === "EXPERT" ? "true" : "false"}
            >
              <span>Expert</span> {user?.isPremium ? null : <FaLock />}
            </StyledBettingCodeNavigationItem>
            <StyledBettingCodeNavigationItem
              onClick={() => handleTabChange("PREMIUM")}
              isactive={selectedTab === "PREMIUM" ? "true" : "false"}
            >
              <span>Premium</span> {user?.isPremium ? null : <FaLock />}
            </StyledBettingCodeNavigationItem>
          </StyledBettingCodeSectionHeaderContainer>
          <StyledBettingCodeSection>
            {/* <StyledBettingCodeSectionHeader>
              <p>{item} ODDs</p>
              <AiOutlineRight />
            </StyledBettingCodeSectionHeader> */}

            <StyledCodeTableContainer>
              {isLoadingBettingCodes ? (
                <PageLoader />
              ) : bettingCodes?.length <= 0 ? (
                <EmptyState />
              ) : (
                bettingCodes?.slice(0, 4).map((item) => (
                  <StyledCodeItem>
                    <StyledCodeLeft>
                      <StyledCodeLogo
                        src={item?.company?.logo_url}
                        alt={item?.company?.name}
                      />
                    </StyledCodeLeft>
                    <StyledCodeRight>
                      <StyledCodePredictionCode>
                        <div className="main">
                          <p style={{ marginRight: ".4rem" }}>{item?.code}</p>
                          <p className="small">by {item?.user?.username}</p>
                        </div>
                        <div className="share-button">
                          <FaShareAlt />
                        </div>
                      </StyledCodePredictionCode>
                      <StyledCodeRatingContainer>
                        <StyledCodeRatingItem>
                          <StyledCodeRatingActionContainer
                            onClick={() =>
                              vote({ id: item?.id, vote_type: "DOWNVOTE" })
                            }
                            active={
                              clicked?.id === item?.id &&
                              clicked.vote_type === "UPVOTE"
                                ? "true"
                                : "false"
                            }
                            type={clicked?.vote_type}
                          >
                            {clicked?.vote_type === "UPVOTE" &&
                            isVoting &&
                            clicked?.id === item?.id ? (
                              <LoadingSpinner />
                            ) : (
                              <FaThumbsUp />
                            )}
                          </StyledCodeRatingActionContainer>
                          <StyledCodeRatingTotal>
                            <p>{item?.upVotes}</p>
                          </StyledCodeRatingTotal>
                        </StyledCodeRatingItem>
                        <StyledCodeRatingItem>
                          <StyledCodeRatingActionContainer
                            onClick={() =>
                              vote({ id: item?.id, vote_type: "DOWNVOTE" })
                            }
                            type={clicked?.vote_type}
                          >
                            {clicked?.vote_type === "UPVOTE" &&
                            isVoting &&
                            clicked?.id === item?.id ? (
                              <LoadingSpinner />
                            ) : (
                              <FaThumbsDown />
                            )}
                          </StyledCodeRatingActionContainer>
                          <StyledCodeRatingTotal>
                            <p>{item?.downVotes}</p>
                          </StyledCodeRatingTotal>
                        </StyledCodeRatingItem>
                      </StyledCodeRatingContainer>
                    </StyledCodeRight>
                  </StyledCodeItem>
                ))
              )}
            </StyledCodeTableContainer>
            {/* <StyledBettingCodeSectionBody
              maxHeight={item === expandedItem ? contentHeight ?? 0 : 0}
            >
              <Content ref={contentRef}>
                <StyledBettingCodeTable>
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th>Market</th>
                        <th>Pick</th>
                        <th>Odd</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>20:45</td>
                        <td>Kano Rangers vs FC Barcelona</td>
                        <td>1X2</td>
                        <td>Home</td>
                        <td>0.08</td>
                      </tr>
                      <tr>
                        <td>20:45</td>
                        <td>Kano Rangers vs FC Barcelona</td>
                        <td>1X2</td>
                        <td>Home</td>
                        <td>0.08</td>
                      </tr>
                      <tr>
                        <td>20:45</td>
                        <td>Kano Rangers vs FC Barcelona</td>
                        <td>1X2</td>
                        <td>Home</td>
                        <td>0.08</td>
                      </tr>
                      <tr>
                        <td>20:45</td>
                        <td>Kano Rangers vs FC Barcelona</td>
                        <td>1X2</td>
                        <td>Home</td>
                        <td>0.08</td>
                      </tr>
                    </tbody>
                  </table>
                  <h3>Total Odds: 1.65</h3>
                  <StyledBettingCodesContainer>
                    {bettingCodes.map((item) => (
                      <StyledBettingCodeItem
                        color_theme={
                          item?.betting_platform === "SPORTY_BET"
                            ? "red"
                            : item.betting_platform === "BET_WAY"
                            ? "grey"
                            : item.betting_platform === "FUN_88"
                            ? "blue"
                            : item.betting_platform === "BET_9JA"
                            ? "green"
                            : ""
                        }
                      >
                        <StyledCodeLogo
                          src={
                            item?.betting_platform === "SPORTY_BET"
                              ? SportyBetLogo
                              : item.betting_platform === "BET_WAY"
                              ? BetwayLogo
                              : item.betting_platform === "FUN_88"
                              ? Fun88Logo
                              : item.betting_platform === "BET_9JA"
                              ? Bet9jaLogo
                              : ""
                          }
                          alt={item.betting_platform}
                        />
                        <h4>{item.betting_code}</h4>
                      </StyledBettingCodeItem>
                    ))}
                  </StyledBettingCodesContainer>
                </StyledBettingCodeTable>
              </Content>
            </StyledBettingCodeSectionBody> */}
          </StyledBettingCodeSection>
          {/* ))} */}
        </main>
        <StyledLayoutRightContent>
          <div className="w-full ">
            <img
              src={LivePlayers}
              className="w-full object-cover"
              alt="side banner"
            />
          </div>
        </StyledLayoutRightContent>
      </div>
      {/* <SectionHeader title="Add-Ons" />
      <main>
        {[
          "1.5 Goals",
          "Double Chance",
          "Correct Score",
          "BTS",
          "2.5 Goals",
          "Win Either Halves",
          "Multi Goals",
          "Highest Scoring Half",
          "Goal Goal",
          "3.5 Goals",
          "Home or Over 2.5",
        ].map((item) => (
          <StyledBettingCodeSection onClick={() => handleExpandToggle(item)}>
            <StyledBettingCodeSectionHeader>
              <p>{item}</p>
              <AiOutlineRight />
            </StyledBettingCodeSectionHeader>
            <StyledBettingCodeSectionBody
              maxHeight={item === expandedItem ? contentHeight ?? 0 : 0}
            >
              <Content ref={contentRef}>
                <StyledBettingCodeTable>
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th>Market</th>
                        <th>Pick</th>
                        <th>Odd</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>20:45</td>
                        <td>Kano Rangers vs FC Barcelona</td>
                        <td>1X2</td>
                        <td>Home</td>
                        <td>0.08</td>
                      </tr>
                      <tr>
                        <td>20:45</td>
                        <td>Kano Rangers vs FC Barcelona</td>
                        <td>1X2</td>
                        <td>Home</td>
                        <td>0.08</td>
                      </tr>
                      <tr>
                        <td>20:45</td>
                        <td>Kano Rangers vs FC Barcelona</td>
                        <td>1X2</td>
                        <td>Home</td>
                        <td>0.08</td>
                      </tr>
                      <tr>
                        <td>20:45</td>
                        <td>Kano Rangers vs FC Barcelona</td>
                        <td>1X2</td>
                        <td>Home</td>
                        <td>0.08</td>
                      </tr>
                    </tbody>
                  </table>
                  <h3>Total Odds: 1.65</h3>
                  <StyledBettingCodesContainer>
                    {bettingCodes.map((item) => (
                      <StyledBettingCodeItem
                        color_theme={
                          item?.betting_platform === "SPORTY_BET"
                            ? "red"
                            : item.betting_platform === "BET_WAY"
                            ? "grey"
                            : item.betting_platform === "FUN_88"
                            ? "blue"
                            : item.betting_platform === "BET_9JA"
                            ? "green"
                            : ""
                        }
                      >
                        <StyledCodeLogo
                          src={
                            item?.betting_platform === "SPORTY_BET"
                              ? SportyBetLogo
                              : item.betting_platform === "BET_WAY"
                              ? BetwayLogo
                              : item.betting_platform === "FUN_88"
                              ? Fun88Logo
                              : item.betting_platform === "BET_9JA"
                              ? Bet9jaLogo
                              : ""
                          }
                          alt={item.betting_platform}
                        />
                        <h4>{item.betting_code}</h4>
                      </StyledBettingCodeItem>
                    ))}
                  </StyledBettingCodesContainer>
                </StyledBettingCodeTable>
              </Content>
            </StyledBettingCodeSectionBody>
          </StyledBettingCodeSection>
        ))}
      </main> */}
    </StyledBettingCodePageContainer>
  );
};
