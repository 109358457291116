import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledFooterWrapper = styled.footer`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.container};
  margin-top: 3rem;
`;

export const StyledFooterContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 3rem 0px;
  font-size: 14px;

  img {
    width: 10rem;
  }

  p {
    margin: 1rem 0px;
  }
`;

export const StyledLinkItem = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.text};
  margin: 0px 0.3rem;
  transition: 0.3s ease;

  :hover {
    color: ${({ theme }) => theme.palette.primary};
  }
`;

export const StyledFooterIconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.4rem;
`;

export const StyledFooterIcon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%; /* Changed to 50% for a perfect circle */
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.primary};
  transition: 0.3s ease;
  text-decoration: none; /* Ensure no underline on links */

  svg {
    transition: 0.3s ease;
    fill: #000;
  }

  &:hover {
    transform: scale(1.09);
    svg {
      fill: ${({ theme }) => theme.palette.text};
    }
  }
`;