export enum Endpoints {
  LOGIN = "/auth/login",
  REGISTER = "/auth/register",
  GET_ALL_BETTING_CODES = "/betting-codes",
  GET_ALL_BETTING_COMPANIES = "/betting-codes/companies",
  CREATE_BETTING_CODE = "/betting-codes",
  VOTE_BETTING_CODE = "/betting-codes",
  GET_ALL_PREDICTIONS = "/predictions",
  GET_ALL_NEWS = "/news",
}

export enum QueryKeys {
  GET_ALL_BETTING_CODES = "GET_ALL_BETTING_CODES",
  GET_ALL_PREDICTIONS = "GET_ALL_PREDICTIONS",
  GET_ALL_BETTING_COMPANIES = "GET_ALL_BETTING_COMPANIES",
  GET_ALL_NEWS = "GET_ALL_NEWS",
}
