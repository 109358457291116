import styled from "styled-components";

export const StyledSectionHeaderContainer = styled.div`
  padding: 0px 1rem;
  margin-top: 3rem;
  h2 {
    font-size: 1.5rem;
    text-transform: capitalize;
    font-weight: bold;
    color: ${({ theme }) => theme.palette.red};
  }
`;
