import { closeAuthModal, logoutUser } from "@/app/store";
import { Logo } from "@/assets";
import { useAppDispatch, useAppSelector, useDisclosure } from "@/hooks";
import { getUserTimeZone } from "@/utils";
import { useEffect, useState } from "react";
import { BiBell, BiMenuAltLeft } from "react-icons/bi";
import { ImCancelCircle } from "react-icons/im";
import { Link, NavLink } from "react-router-dom";
import {
  CreateAccountModal,
  ForgotPasswordModal,
  LoginModal,
  ResetAccountPasswordModal,
} from "./components";

export const Header = () => {
  const dispatch = useAppDispatch();
  const { authModalIsOpen } = useAppSelector((state) => state.app);
  const access_token = useAppSelector((state) => state.user.access_token);
  const user = useAppSelector((state) => state.user.user);
  const [menuOpen, setMenuOpen] = useState(false);
  const [, setScroll] = useState(false);
  const pageLink = window.location.pathname;
  const { isOpen, onClose, onOpen } = useDisclosure(authModalIsOpen);

  const handleCloseLoginModal = () => {
    dispatch(closeAuthModal());
    onClose();
  };

  useEffect(() => {
    if (authModalIsOpen === true) {
      onOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authModalIsOpen]);

  const {
    isOpen: isOpenForgotPasswordModal,
    onClose: onCloseForgotPasswordModal,
    onOpen: onOpenForgotPasswordModal,
  } = useDisclosure();

  const {
    isOpen: isOpenResetPasswordModal,
    onClose: onCloseResetPasswordModal,
    onOpen: onOpenResetPasswordModal,
  } = useDisclosure();

  const {
    isOpen: isOpenCreateAccountModal,
    onClose: onCloseCreateAccountModal,
    onOpen: onOpenCreateAccountModal,
  } = useDisclosure();

  const [userTime, setUserTime] = useState<{
    currentTime: string;
    timezone: string;
  }>({ currentTime: "", timezone: "" });
  getUserTimeZone((data) => {
    setUserTime({
      currentTime: data?.currentTime,
      timezone: data?.timeZone,
    });
  });
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setScroll(window.pageYOffset > 200)
      );
    }
    // eslint-disable-next-line
  }, []);

  const openModal = () => {
    onOpen();
    setMenuOpen(!menuOpen);
  };
  const [open, setOpen] = useState(false);
  const handleDropDown = () => {
    setOpen(!open);
  };

  const logout = () => {
    dispatch(logoutUser());
    localStorage.clear();
  };

  return (
    <header className={`w-full ${`sticky h-[90px] top-0 z-[2000] bg-black `} `}>
      <div
        className={`flex justify-between w-[95%]  h-full  mx-auto items-center align-center`}
      >
        <div
          className="flex w-[70%] md:w-[40%] lg:w-[45%] gap-5 items-center cursor-pointer"
        >
          <div className="w-[100px] md:w-[120px] lg:w-[200px]">
        <a href="/" >
    <img src={Logo} alt="aradugbo_logo" className="w-full" />
  </a>
</div>

          <div>
            <p className="text-[.4rem] md:text-[.8rem] bg-[#161616] p-2">
              {userTime?.currentTime + " " + userTime?.timezone}
            </p>
          </div>
        </div>
        <ul className=" lg:flex gap-4 text-[.8rem] items-center justify-end hidden  ">
          <li className="">
            <NavLink
              className={`${
                pageLink === "/" ? "text-[#FCFCFD]" : "text-[#F2F4F7]"
              } hover:text-red-500`}
              to="/"
            >
              Home
            </NavLink>
          </li>
          <li className="">
            <NavLink
              className={`${
                pageLink === "/betting-codes" ? "text-[red]" : "text-[#F2F4F7]"
              } hover:text-red-500`}
              to="/betting-codes"
            >
              Betting Codes
            </NavLink>
          </li>
          <li className="">
            <NavLink
              className={`${
                pageLink === "/predictions" ? "text-[red]" : "text-[#F2F4F7]"
              } hover:text-red-500`}
              to="/predictions"
            >
              Prediction
            </NavLink>
          </li>

          <li className="">
            <NavLink
              className={`${
                pageLink === "/news?type=news" ? "text-[red]" : "text-[#F2F4F7]"
              } hover:text-red-500`}
              to="/news?type=news"
            >
              News
            </NavLink>
          </li>
          {access_token && (
            <>
              <div className="relative ">
                <button
                  className="text-white  bg-transparent focus:bg-red-500focus:ring-1 focus:outline-none
              font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex
              items-center "
                  type="button"
                  onClick={handleDropDown}
                >
                  {user?.username}{" "}
                  <svg
                    className="w-2.5 h-2.5 ml-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </button>

                {open && (
                  <div className="z-10 absolute  bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-[#171717e9]">
                    <ul
                      className="py-2 text-sm text-gray-700 dark:text-gray-200"
                      aria-labelledby="dropdownHoverButton"
                    >
                      <li>
                        <a
                          // eslint-disable-next-line
                          href="#"
                          className="block px-4 py-2 hover:text-red-500 hover:bg-[#171717e9] "
                        >
                          Settings
                        </a>
                      </li>
                      <li>
                        <a
                          // eslint-disable-next-line
                          href="#"
                          className="block px-4 py-2 hover:bg-[#171717e9] hover:text-red-500"
                        >
                          Balance
                        </a>
                      </li>
                      <li>
                        <a
                          // eslint-disable-next-line
                          href="#"
                          className="block px-4 py-2 hover:bg-[#171717e9] hover:text-red-500"
                          onClick={logout}
                        >
                          Sign out
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <BiBell className="w-5 hover:text-red-500 cursor-pointer" />
            </>
          )}
          {!access_token && (
            <div className="w-[%] ">
              <button
                className={`
                text-white px-[1rem] py-[.5rem] bg-[#ff0000] rounded-md
             ml-3`}
                onClick={onOpen}
              >
                Login/Sign up
              </button>
            </div>
          )}
        </ul>
        <div
          className="cursor-pointer"
          // onClick={handleOpenSidebar}
        >
          <BiMenuAltLeft
            className="lg:hidden w-8 text-primary cursor-pointer"
            size={24}
            onClick={() => setMenuOpen(true)}
          />
        </div>
        {/* mobile menu start */}

        {menuOpen && (
          <div
            className="fixed left-0 right-0 bottom-0 h-screen w-full lg:hidden bg-[#0000003d] z-[99999]"
            onClick={() => setMenuOpen(false)}
          >
            {/* <AnimatePresence> */}
            <div
              // initial={{ x: -100 }}
              // animate={{ x: 0 }}
              // exit={{ x: -100 }}
              className="fixed top-0 left-0 bg-[#f9f9f9] h-screen overflow-y-scroll"
              onClick={(e) => e.stopPropagation()}
            >
              <aside className="flex relative bg-black flex-col gap-6  w-[200px]  h-full">
                <div className="px-[1rem] flex justify-between items-center   mt-[2rem] mb-4">
                  <Link to="/">
                    <img
                      src={Logo}
                      alt="logo"
                      className="w-[60px] mr-4"
                      onClick={() => setMenuOpen(false)}
                    />
                  </Link>
                  <ImCancelCircle
                    className="w-5 text-red-500 cursor-pointer"
                    onClick={() => setMenuOpen(false)}
                  />
                </div>

                <nav>
                  <ul className=" flex flex-col gap-4 py-5 px-6 ">
                    <li className="flex  items-center cursor-pointer">
                      <NavLink
                        className={
                          pageLink === "/" ? "text-[#FCFCFD]" : "text-[#F2F4F7]"
                        }
                        to="/"
                      >
                        Home
                      </NavLink>
                    </li>
                    <li className="flex  items-center cursor-pointer">
                      <NavLink
                        className={
                          pageLink === "/betting-codes"
                            ? "text-[red]"
                            : "text-[#F2F4F7]"
                        }
                        to="/betting-codes"
                      >
                        Betting Codes
                      </NavLink>
                    </li>
                    <li className="flex  items-center cursor-pointer">
                      <NavLink
                        className={`${
                          pageLink === "/predictions"
                            ? "text-[red]"
                            : "text-[#F2F4F7]"
                        } hover:text-[red]  `}
                        to="/predictions"
                      >
                        Prediction
                      </NavLink>
                    </li>
                    <li className="flex  items-center cursor-pointer">
                      <NavLink
                        className={`${
                          pageLink === "/live-score"
                            ? "text-[red]"
                            : "text-[#F2F4F7]"
                        } hover:text-[red]  `}
                        to="/live-score"
                      >
                        Livescore
                      </NavLink>
                    </li>
                    <li className="flex  items-center cursor-pointer">
                      <NavLink
                        className={`${
                          pageLink === "/news?type=news"
                            ? "text-[red]"
                            : "text-[#F2F4F7]"
                        } hover:text-[red]  `}
                        to="/news?type=news"
                      >
                        News
                      </NavLink>
                    </li>
                  </ul>

                  {access_token && (
                    <>
                      <div className="absolute  w-full  bottom-3 border-t-[.2px] py-5 px-6 border-[#d0cdcd] ">
                        <button
                          className="text-white mx-auto w-full justify-between bg-transparent  
              font-medium rounded-lg text-sm text-center inline-flex
              items-center "
                          type="button"
                          onClick={handleDropDown}
                        >
                          {user?.username}{" "}
                          <svg
                            className="w-2.5 h-2.5 float-right ml-2.5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 10 6"
                          >
                            <path
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="m1 1 4 4 4-4"
                            />
                          </svg>
                        </button>

                        {open && (
                          <div className="z-10 absolute -top-[10rem] bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-[#171717e9]">
                            <ul
                              className="py-2 text-sm text-gray-700 dark:text-gray-200"
                              aria-labelledby="dropdownHoverButton"
                            >
                              <li>
                                <a
                                  href="#"
                                  className="block px-4 py-2 hover:text-[red] dark:hover:bg-[#171717e9] "
                                >
                                  Settings
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  className="block px-4 py-2 hover:text-[red] dark:hover:bg-[#171717e9] "
                                >
                                  Balance
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  className="block px-4 py-2 hover:text-[red] dark:hover:bg-[#171717e9] "
                                  onClick={logout}
                                >
                                  Sign out
                                </a>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {!access_token && (
                    <div className="mt-[2rem]">
                      <button
                        className={`
                text-white text-[.8rem] px-[.5rem] py-[.5rem] bg-[#ff0000] rounded-md
             ml-3`}
                        onClick={() => openModal()}
                      >
                        Login/Sign up
                      </button>
                    </div>
                  )}
                </nav>
              </aside>
            </div>
          </div>
        )}
      </div>
      <LoginModal
        isOpen={isOpen}
        onClose={handleCloseLoginModal}
        onOpenCreateAccountModal={onOpenCreateAccountModal}
        onOpenForgotPasswordModal={onOpenForgotPasswordModal}
      />
      <CreateAccountModal
        isOpen={isOpenCreateAccountModal}
        onClose={onCloseCreateAccountModal}
        onOpenLoginModal={onOpen}
      />
      <ForgotPasswordModal
        isOpen={isOpenForgotPasswordModal}
        onClose={onCloseForgotPasswordModal}
        onOpenLoginModal={onOpen}
        onOpenResetPasswordModal={onOpenResetPasswordModal}
      />
      <ResetAccountPasswordModal
        isOpen={isOpenResetPasswordModal}
        onClose={onCloseResetPasswordModal}
      />
    </header>
  );
};
