import { httpClient } from "@/network/api";
import {
  BaseError,
  Endpoints,
  IGetAllNewsResponse,
  IGetSingleNewsResponse,
  News,
  QueryKeys,
} from "@/network/interface";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useParams, useSearchParams } from "react-router-dom";

export const useGetNews = () => {
  // max news that can be rendered ==== 8
  const [searchParams] = useSearchParams();
  const news_type = searchParams.get("type");

  const [news, setNews] = useState<Array<News>>([]);

  const { isLoading, refetch, isRefetching } = useQuery(
    [QueryKeys.GET_ALL_NEWS],
    async () => {
      const { data } = await httpClient.get<IGetAllNewsResponse>(
        `${Endpoints.GET_ALL_NEWS}?type=${news_type}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        setNews(data?.data);
      },
      onError: (error: BaseError) => {
        const message = error?.response?.data?.message;
        toast.error(message);
      },
    }
  );

  const loadMore = () => {
    refetch();
  };

  return { isLoading, isRefetching, news, loadMore };
};

export const useGetSingleNews = () => {
  const { slug } = useParams();

  const [news, setNews] = useState<News | null>(null);

  const { isLoading } = useQuery(
    [QueryKeys.GET_ALL_NEWS],
    async () => {
      const { data } = await httpClient.get<IGetSingleNewsResponse>(
        `${Endpoints.GET_ALL_NEWS}/${slug}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        setNews(data?.data);
      },
      onError: (error: BaseError) => {
        const message = error?.response?.data?.message;
        toast.error(message);
      },
    }
  );

  return { isLoading, news };
};
