import { BarcaLogo } from "@/assets";
import { Footer, GameTypeHeader, Header } from "@/components";
import { Outlet, useLocation } from "react-router-dom";
import {
  StyledLayoutCoinsContainer,
  StyledLayoutContentContainer,
  StyledLayoutLeftContent,
  StyledLayoutMainContainer,
  StyledLayoutRightContent, StyledLayoutWrapper, StyledLivePlayersContainer, StyledLoadMoreButton,
  StyledTopMatchContainer,
  StyledTopMatchHeaderContainer,
  StyledVersusContainer,
  StyledVersusItem,
  StyledVersusText
} from "./layout.styles";

export const HomeLayout = ( ) => {
  const { pathname } = useLocation();

  const show = pathname === "/" || pathname.includes("news");

  const showHeader =
    pathname.includes("betting-codes") || pathname.includes("predictions");

  return (
    <StyledLayoutWrapper>
      <Header />
      <StyledLayoutMainContainer>
        {show ? (
          <StyledLayoutLeftContent>
            <StyledLayoutCoinsContainer />
            <StyledTopMatchContainer>
              <StyledTopMatchHeaderContainer>
                <p>Top Match</p>
                <p>04 Jan 15:30</p>
              </StyledTopMatchHeaderContainer>
              <h1>BUNDESLIGA</h1>
              <StyledVersusContainer>
                <StyledVersusItem className="left">
                  <img src={BarcaLogo} alt="team" />
                  <p>Kano Rangers</p>
                  <h3>0.52</h3>
                </StyledVersusItem>
                <StyledVersusText>VS</StyledVersusText>
                <StyledVersusItem className="right">
                  <img src={BarcaLogo} alt="team" />
                  <p>FC Barcelona</p>
                  <h3>0.52</h3>
                </StyledVersusItem>
              </StyledVersusContainer>
              <StyledLoadMoreButton>
                <p>Load More</p>
              </StyledLoadMoreButton>
            </StyledTopMatchContainer>
          </StyledLayoutLeftContent>
        ) : null}
        <StyledLayoutContentContainer isHome={show ? "true" : "false"}>
          {showHeader ? <GameTypeHeader showGames={!showHeader} /> : null}
          <Outlet />
        </StyledLayoutContentContainer>
        {show ? (
          <StyledLayoutRightContent>
            <StyledLivePlayersContainer />
          </StyledLayoutRightContent>
        ) : null}
      </StyledLayoutMainContainer>
      <Footer />
    </StyledLayoutWrapper>
  );
};
