import styled from "styled-components";

export const StyledBettingCodePageContainer = styled.main`
  width: 100%;
  margin: 0 auto;
  // min-height: 100%;
`;

export const StyledBettingCodeSection = styled.div`
  width: 100%;
  padding-top: 1rem;
  margin-top: 0.8rem;
  background-color: ${({ theme }) => theme.palette.container};
  border-radius: ${({ theme }) => theme.borderRadiusSM};
`;

export const StyledBettingCodeSectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.3rem 0px;
  cursor: pointer;
  p {
    font-size: 1rem;
    font-weight: bold;
  }
`;

export const StyledBettingCodeSectionBody = styled.div<{ maxHeight: number }>`
  max-height: ${({ maxHeight }) => `${maxHeight}px`};
  transition: max-height 0.25s ease-in-out;
  overflow: hidden;
`;

export const Content = styled.div`
  padding: 0 0 1rem;
  color: rgba(0, 0, 0, 0.75);
  line-height: 1.5;
`;

export const StyledBettingCodeTable = styled.div`
  width: 100%;

  table {
    width: 100%;
    border-collapse: collapse;
    color: #ffffff;
  }

  table td,
  table th {
    padding: 5px;
    text-align: center;
    font-size: 0.9rem;
  }

  table tr {
    border-radius: ${({ theme }) => theme.borderRadiusXS};
    :nth-child(even) {
      background-color: ${({ theme }) => theme.palette.container_light};
    }
  }

  table thead {
    /* background-color: #7ea8f8; */
  }

  h3 {
    text-align: right;
    font-size: 1.2rem;
    margin: 1rem 0px;
  }
`;

export const StyledBettingCodesContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.3rem;
  flex-wrap: wrap;
`;

export const StyledBettingCodeItem = styled.div<{ color_theme: string }>`
  width: 16%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ color_theme, theme }) =>
    color_theme === "red"
      ? theme.palette.red
      : color_theme === "green"
      ? theme.palette.green[500]
      : color_theme === "blue"
      ? theme.palette.blue
      : color_theme === "grey"
      ? "transparent"
      : ""};
  border-radius: ${({ theme }) => theme.borderRadiusXS};

  h4 {
    font-size: 1rem;
  }
`;

export const StyledCodeLogo = styled.img`
  width: 7rem;
  height: 3rem;
  object-fit: cover;
  display: block;
`;

export const StyledBettingCodeSectionHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
  background-color: ${({ theme }) => theme.palette.container};
  padding: 0.5rem;
  width: fit-content;
  margin-top: 2rem;
`;

export const StyledBettingCodeNavigationItem = styled.p<{
  isactive: string;
}>`
  font-size: 1rem;
  position: relative;
  padding: 0.8rem 2rem;
  border-radius: ${({ theme }) => theme.borderRadiusSM};
  background-color: ${({ isactive, theme }) =>
    isactive === "true" ? theme.palette.container_light : "transparent"};
  color: ${({ isactive, theme }) =>
    isactive === "true" ? theme.palette.text : theme.palette.container_light};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  span: {
    color: inherit;
  }
`;
