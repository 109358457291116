export const Sizes = {
  mobile: "500px",
  tablet: "768px",
  laptop: "1024px",
  desktop: "1280px",
  large: "1440px",
};

export const devices = {
  mobile: `(max-width: ${Sizes.mobile})`,
  tablet: `(max-width: ${Sizes.tablet})`,
  laptop: `(max-width: ${Sizes.laptop})`,
  desktop: `(max-width: ${Sizes.desktop})`,
  large: `(max-width: ${Sizes.large})`,
};