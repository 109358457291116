import { toast } from "react-hot-toast";
import Img from "../../assets/Group3.jpg";
import Side from "../../assets/side.png";
import Slide from "../../assets/slide.png";

export const truncate = (text: string, maxLength: number) => {
  if (text?.length <= maxLength) {
    return text;
  }

  const trimmedText = text?.substr(0, maxLength);
  const lastSpaceIndex = trimmedText?.lastIndexOf(" ");
  const truncatedText = trimmedText?.substr(0, lastSpaceIndex);

  return truncatedText + "...";
};

export const roundUpToOneDecimal = (number: number) => {
  const roundedNumber = Math.ceil(number * 10) / 10;
  return roundedNumber.toFixed(1);
};

export const projects = [
  {
    name: "Pool",
    description:
      "Fractionalized investment protocol that democratizes access to premium digital and real world assets. Pool is a multi-buyer/crowdvesting platform where investors can invest in valuables such as NFTs and other digital assets such as RPCs. Real world assets would include real estate, gold etc.",
    image: Img,
  },
  {
    name: " Hive",
    description:
      "Hive is a web 3 talent marketplace that bridges the gap between talents and projects in a secure and result-driven way. Hive talents would be able to work to achieve verification, giving them a higher level of visibility and credibility with projects in search of talents.",
    image: Side,
  },
 
  {
    name: "Pool",
    description:
      "Fractionalized investment protocol that democratizes access to premium digital and real world assets. Pool is a multi-buyer/crowdvesting platform where investors can invest in valuables such as NFTs and other digital assets such as RPCs. Real world assets would include real estate, gold etc.",
    image: Img,
  },
  {
    name: "Pool",
    description:
      "Fractionalized investment protocol that democratizes access to premium digital and real world assets. Pool is a multi-buyer/crowdvesting platform where investors can invest in valuables such as NFTs and other digital assets such as RPCs. Real world assets would include real estate, gold etc.",
    image: Slide,
  },
];


export const errorPopUp = ({ msg }:any) => {
  toast.error(msg);
};

export const successPopUp = ({ msg  }:any) => {
  toast.success(msg);
};
