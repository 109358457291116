import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  FLUSH, PAUSE,
  PERSIST, persistReducer, persistStore, PURGE,
  REGISTER, REHYDRATE
} from 'redux-persist';
import localStorage from "redux-persist/es/storage";
import AppReducer from "./reducers/AppReducer";
import userReducer from "./reducers/UserReducer";
const persistConfig = {
  key: "app_root",
  storage: localStorage,
};

const rootReducer = combineReducers({
  user: userReducer, 
  app: AppReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

  export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],}
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
