import { AppRouter } from "@/router";
import { GlobalStyles, theme } from "@/utils";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "styled-components";
import "./App.css";
import { persistor, store } from "./app/store";
const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 1,
        refetchOnWindowFocus: true,
      },
    },
  });

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            <AppRouter />
          </ThemeProvider>
          <Toaster position="bottom-right" />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
