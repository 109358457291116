import styled from "styled-components";

export const BettingCodeModalContainer = styled.div`
  width: 100%;
  height: 100%;

  h1 {
    margin-bottom: 2rem;
    text-align: center;
    font-size: 1.3rem;
  }

  .centered {
    input {
      text-align: center;
    }
    ::placeholder {
      text-align: center;
    }
  }
`;

export const StyledBettingCodeBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
