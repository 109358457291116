import { News } from "@/network/interface";
import { truncate } from "@/utils";
import { StyledNewsBannerContainer } from "./news.banner.styles";

interface IProps {
  data?: News;
}

export const Newsbanner = ({ data }: IProps) => {
  return (
    <StyledNewsBannerContainer to={`/news/${data?.slug}`}>
      <img src={data?.featured_image} alt={data?.title} />
      <h3>{truncate(data?.title ?? "", 90)}</h3>
      <p>{truncate(data?.body ?? "", 150)} </p>
      {/* <h3>Read More</h3> */}

      {/* <p>{data?.body}</p> */}
    </StyledNewsBannerContainer>
  );
};
