import { SectionHeader } from "@/components";
import { useGetAllPredictions } from "@/network/hooks";
import moment from "moment";
import {
  StyledPredictionGamePlayersContainer,
  StyledPredictionTableBodyItem,
  StyledPredictionTableBodyLeft,
  StyledPredictionTableContainer,
  StyledPredictionTableHeader,
  StyledPredictionTableHeaderItem,
  StyledPredictionTableHeaderLeft,
  StyledPredictionTableHeaderRight,
  StyledTableBodyContainer,
} from "../Home/home.styles";
import {
  StyledPredictionItem,
  StyledPredictionPageContainer,
} from "./prediction.styles";

// Function to round a number up to one decimal place
const roundUpToOneDecimal = (num: number): number => {
  return Math.round(num * 10) / 10;
};

export const PredictionPage = () => {
  const { isLoading, isRefetching, predictions } = useGetAllPredictions();

  // Define the type of the accumulator
  type GroupedPredictions = {
    [key: string]: { competition_name: string; items: typeof predictions };
  };

  // Sort and group predictions by competition_cluster
  const groupedPredictions = predictions?.reduce<GroupedPredictions>((acc, item) => {
    const cluster = item.competition_cluster;
    if (!acc[cluster]) {
      acc[cluster] = {
        competition_name: item.competition_name,
        items: [],
      };
    }
    acc[cluster].items.push(item);
    return acc;
  }, {});

  return (
    <StyledPredictionPageContainer>
      <SectionHeader title="All Predicted Matches" />
      <StyledPredictionItem>
        <StyledPredictionTableContainer>
          <StyledPredictionTableHeader>
            <StyledPredictionTableHeaderLeft />
            <StyledPredictionTableHeaderRight>
              <StyledPredictionTableHeaderItem>
                <p>TIP</p>
              </StyledPredictionTableHeaderItem>
              <StyledPredictionTableHeaderItem>
                <p>1</p>
              </StyledPredictionTableHeaderItem>
              <StyledPredictionTableHeaderItem>
                <p>X</p>
              </StyledPredictionTableHeaderItem>
              <StyledPredictionTableHeaderItem>
                <p>2</p>
              </StyledPredictionTableHeaderItem>
              <StyledPredictionTableHeaderItem>
                <p>1X</p>
              </StyledPredictionTableHeaderItem>
              <StyledPredictionTableHeaderItem>
                <p>12</p>
              </StyledPredictionTableHeaderItem>
              <StyledPredictionTableHeaderItem>
                <p>X2</p>
              </StyledPredictionTableHeaderItem>
            </StyledPredictionTableHeaderRight>
          </StyledPredictionTableHeader>
          {isLoading || isRefetching ? (
            <p>Loading...</p>
          ) : (
            Object.keys(groupedPredictions || {}).map((cluster, index) => (
              <div key={index}>
                <h2>
                  {cluster} - {groupedPredictions[cluster].competition_name}
                </h2>
                {groupedPredictions[cluster].items.map((item, _idx) => (
                  <StyledTableBodyContainer key={_idx}>
                    <StyledPredictionTableBodyLeft>
                      <p className="time-stamp">
                        {moment(item?.start_date).format("Do-MM-YYYY")}
                      </p>
                      <StyledPredictionGamePlayersContainer>
                        <p>{item?.home_team}</p>
                        <p>{item?.away_team}</p>
                      </StyledPredictionGamePlayersContainer>
                    </StyledPredictionTableBodyLeft>
                    <StyledPredictionTableHeaderRight>
                      <StyledPredictionTableBodyItem className="tip">
                        <p>{item?.prediction}</p>
                      </StyledPredictionTableBodyItem>
                      <StyledPredictionTableBodyItem className="one">
                        <p>{roundUpToOneDecimal(item?.odds?.[1] ?? 0)}</p>
                      </StyledPredictionTableBodyItem>
                      <StyledPredictionTableBodyItem className="x">
                        <p>{roundUpToOneDecimal(item?.odds?.X ?? 0)}</p>
                      </StyledPredictionTableBodyItem>
                      <StyledPredictionTableBodyItem className="two">
                        <p>{roundUpToOneDecimal(item?.odds?.[2] ?? 0)}</p>
                      </StyledPredictionTableBodyItem>
                      <StyledPredictionTableBodyItem className="onex">
                        <p>{roundUpToOneDecimal(item?.odds?.["1X"] ?? 0)}</p>
                      </StyledPredictionTableBodyItem>
                      <StyledPredictionTableBodyItem className="onetwo">
                        <p>{roundUpToOneDecimal(item?.odds?.[12] ?? 0)}</p>
                      </StyledPredictionTableBodyItem>
                      <StyledPredictionTableBodyItem className="twox">
                        <p>{roundUpToOneDecimal(item?.odds?.X2 ?? 0)}</p>
                      </StyledPredictionTableBodyItem>
                    </StyledPredictionTableHeaderRight>
                  </StyledTableBodyContainer>
                ))}
              </div>
            ))
          )}
        </StyledPredictionTableContainer>
      </StyledPredictionItem>
    </StyledPredictionPageContainer>
  );
};
