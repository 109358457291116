import { login, signup } from "@/network/api";
import { IUser } from "@/network/interface";
import { errorPopUp, successPopUp } from "@/utils";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

const userInitState: IUser = {
  access_token: "",
  loading: false,
  user: {
    email: "",
    username: "",
    avatar: null,
    created_at: "",
    dob: null,
    email_verified_at: null,
    first_name: null,
    gender: null,
    header_image: null,
    id: 0,
    is_active: 0,
    last_name: null,
    phone_number: null,
    updated_at: "",
    isPremium: false,
    expires_in: 0,
  },
  issue:""
};

  

export const signin = createAsyncThunk("/user/signin", async (payload:any, { rejectWithValue }) => {
  try {
    const response = await login(payload);


    successPopUp(
      {
      msg:response?.data?.message
    }
    );
  
    return response.data.data;
  } catch (err) {
   
    errorPopUp({ msg: "Error occured" });
    return rejectWithValue(err);
  }
});

export const register = createAsyncThunk("/user/register", async ( payload:any, { rejectWithValue }) => {
  try {
    const response = await signup(payload);
    if(response){
      successPopUp(
        {
        msg: `Resgistration successful`
      }
      );
      // localStorage.setItem("username", response?.data?.data?.username);
      // localStorage.setItem("token", response?.data?.data?.access_token);
      // localStorage.setItem("user", JSON.stringify({ ...response?.data?.data}));
    }
    return response.data.data;
  } catch (err:any) {
    errorPopUp({ msg: "Error occured"} );
    if(err?.response?.data?.errors?.password || err?.response?.data?.errors?.username ||  err?.response?.data?.errors?.email ){
    errorPopUp({ msg:  err?.response?.data?.errors?.email[0]} );
    errorPopUp({ msg:  err?.response?.data?.errors?.username[0]} );
    }
    return rejectWithValue(err);
  }
});

export const userSlice = createSlice({
  name: "user",
  initialState: userInitState,
  reducers: {
    updateUser: (
      state,
      action: {
        payload: {
          id: number;
          username: string;
          email: string;
          access_token: string;
          token_type: string;
          expires_in: number;
        };
      }
    ) => {
      return {
        ...state,
        access_token: action.payload.access_token,
        user: {
          ...state.user,
          id: action.payload.id,
          username: action.payload.username,
          email: action.payload.email,
          access_token: action.payload.access_token,
          token_type: action.payload.token_type,
          expires_in: action.payload.expires_in,
        },
      };
    },
    updateUserEmail: (state, action: { payload: { email: string } }) => {
      return { ...state, user: { ...state.user, email: action.payload.email } };
    },
    logoutUser: (state) => {
      return userInitState;
    },
  },
  extraReducers: (builder:any) => {
    builder.addCase(signin.pending, (state:any) => {
      state.loading = true;
    });
    builder.addCase(signin.fulfilled, (state:any, action: any) => {
      state.loading = false;
      state.access_token = action.payload.access_token;
      state.user.username = action.payload.username;
      state.user.email = action.payload.email;
      state.user.id = action.payload.id;
      localStorage.setItem("user", JSON.stringify({ ...action.payload }));
    });
    builder.addCase(signin.rejected, (state:any, action:any) => {
      state.loading = false;
      state.user = [];
      state.error = action.error.message;
    });
    builder.addCase(register.pending, (state:any) => {
      state.loading = true;
    });
    builder.addCase(register.fulfilled, (state:any, action: any) => {
      state.loading = false;
      localStorage.setItem("user",  action?.payload );
      state.user = action.payload;
      state.access_token = action.payload.access_token;
    });
    builder.addCase(register.rejected, (state:any, action:any) => {
      state.loading = false;
      state.user = [];
      state.error = action.error.message;
    });
  },
});

export const userSelector = (state: RootState) => state.user;

export const { updateUser, logoutUser, updateUserEmail } = userSlice.actions;
export default userSlice.reducer;
