import { Link } from 'react-router-dom';
import styled from "styled-components";

export const StyledNewsBannerContainer = styled(Link)`
  width: 100%;
  cursor: pointer;
  margin-bottom: 1rem;
  text-decoration: none;

  img {
    width: 100%;
  }

  h3 {
    font-size: 1.3rem;
    margin-bottom: 0.7rem;
  }
  p {
    font-size: 0.8rem;
  }
`;
