import { DefaultTheme } from "styled-components";

export const theme: DefaultTheme = {
  borderRadius: "12px",
  borderRadiusSM: "5px",
  borderRadiusXS: "2px",
  fontSize: "15px",
  palette: {
    background: "#000000",
    text: "#ffffff",
    container: "#161616",
    container_light: "#262626",
    primary: "#FF0000",
    grey: "#7A7979",
    red: "#FF0000",
    blue: "#00B2FF",
    black: "#000",
    yellow: {
      900: "#FCCA2E",
      500: "#FCCA2E",
      100: "#A38004",
    },
    green: {
      900: "#009F79",
      500: "#009F79",
      100: "#08F0B8",
    },
  },
};
