import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import toast from "react-hot-toast";
import { httpClient } from "../api";
import {
  BaseError,
  Endpoints,
  IGetAllPredictionResponse,
  Prediction,
  QueryKeys
} from "../interface";

export const useGetAllPredictions = () => {
  // max news that can be rendered ==== 8

  const [predictions, setPredictons] = useState<Array<Prediction>>([]);

  const { isLoading, refetch, isRefetching } = useQuery(
    [QueryKeys.GET_ALL_PREDICTIONS],
    async () => {
      const { data } = await httpClient.get<IGetAllPredictionResponse>(
        `${Endpoints.GET_ALL_PREDICTIONS}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        setPredictons(data?.data);
      },
      onError: (error: BaseError) => {
       
        const message = error?.response?.data?.message;
        toast.error(message);
      },
    }
  );

  const loadMore = () => {
    refetch();
  };

  return { isLoading, isRefetching, predictions, loadMore };
};
