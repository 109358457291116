import axios from "axios";
import { LoginUserDTO, RegisterUserDTO } from "../interface";

// export const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api`;
export const BASE_URL = `https://fansweek.com/api`;

const headers = {};

export const httpClient = axios.create({
  baseURL: BASE_URL,
  headers,
});

export const login  =(payload: LoginUserDTO) => httpClient.post("/auth/login", payload);
export const signup  =(payload: RegisterUserDTO) => httpClient.post("/auth/register", payload);
