import React from "react";
import { openAuthModal } from "@/app/store";
import { BarcaLogo } from "@/assets";
import { Button, EmptyState, Newsbanner, PageLoader } from "@/components";
import LoadingSpinner from "@/components/Spinner";
import { useAppDispatch, useAppSelector, useDisclosure } from "@/hooks";
import {
  useGetAllBettingCodes,
  useGetAllPredictions,
  useGetNews,
  useVoteBettingCode,
} from "@/network/hooks";
import { FaShareAlt, FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { CreateBettingCodeModal } from "./components";
import {
  StyledBannerContainer,
  StyledBannerLeftContainer,
  StyledBannerRightContainer,
  StyledBannerRightContent,
  StyledBannerRightContentItem,
  StyledBannerRightContentScore,
  StyledCodeItem,
  StyledCodeLeft,
  StyledCodeLogo,
  StyledCodePredictionCode,
  StyledCodeRatingActionContainer,
  StyledCodeRatingContainer,
  StyledCodeRatingItem,
  StyledCodeRatingTotal,
  StyledCodeRight,
  StyledCodeTableContainer,
  StyledFlex,
  StyledFLexHeaderNavigationItemLink,
  StyledFlexItem,
  StyledFlexItemheader,
  StyledNewsContent,
  StyledNewsSection,
  StyledNewsSectionHeader,
  StyledPredictionGamePlayersContainer,
  StyledPredictionTableBodyItem,
  StyledPredictionTableBodyLeft,
  StyledPredictionTableContainer,
  StyledPredictionTableHeader,
  StyledPredictionTableHeaderItem,
  StyledPredictionTableHeaderLeft,
  StyledPredictionTableHeaderRight,
  StyledTableBodyContainer,
  StyledViewMore,
  StyledViewMoreButton,
} from "./home.styles";
import moment from "moment";

const roundUpToOneDecimal = (num: number): number => {
  return Math.round(num * 10) / 10;
};

export const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const access_token = useAppSelector((State) => State.user);

  const {
    isOpen: createBettingCodeModalIsOpen,
    onClose: onCloseCreateBettingCodeModal,
    onOpen: onOpenCreateBettingCodeModal,
  } = useDisclosure();

  const handleOpenCreateBettingCodeModal = () => {
    if (!access_token) {
      dispatch(openAuthModal());
    } else {
      onOpenCreateBettingCodeModal();
    }
  };

  const { bettingCodes, isLoading: isLoadingBettingCodes } = useGetAllBettingCodes();
  const { isLoading, isRefetching, predictions } = useGetAllPredictions();
  const { isLoading: isLoadingNews, news } = useGetNews();
  const { isLoading: isVoting, vote, clicked } = useVoteBettingCode();

  const close = () => {
    onCloseCreateBettingCodeModal();
  };

  const initialPredictionLimit = 5;
  const [shownPredictions, setShownPredictions] = React.useState<number>(initialPredictionLimit);

  const redirectToPredictionsPage = () => {
    navigate("/predictions");
  };

  return (
    <div>
      <StyledBannerContainer>
        <StyledBannerLeftContainer />
        <StyledBannerRightContainer>
          {/* <h1>Fansweek Prediction and News</h1> */}
          {/* <StyledBannerRightContent>
            <StyledBannerRightContentItem>
              <p>Friendly</p>
              <img src={BarcaLogo} alt="club" />
              <p>Kano Rangers</p>
            </StyledBannerRightContentItem>
            <StyledBannerRightContentScore>
              <p>5 : 0</p>
            </StyledBannerRightContentScore>
            <StyledBannerRightContentItem className="right">
              <p>04 Jan 15:30</p>
              <img src={BarcaLogo} alt="club" />
              <p>FC Barcelona</p>
            </StyledBannerRightContentItem>
          </StyledBannerRightContent> */}
        </StyledBannerRightContainer>
      </StyledBannerContainer>

      <StyledFlex>
        <StyledFlexItem>
          <StyledFlexItemheader>
            <h1>Prediction</h1>
          </StyledFlexItemheader>
          <StyledPredictionTableContainer>
            <StyledPredictionTableHeader>
              <StyledPredictionTableHeaderLeft />
              <StyledPredictionTableHeaderRight>
                <StyledPredictionTableHeaderItem>
                  <p>TIP</p>
                </StyledPredictionTableHeaderItem>
                <StyledPredictionTableHeaderItem>
                  <p>1</p>
                </StyledPredictionTableHeaderItem>
                <StyledPredictionTableHeaderItem>
                  <p>X</p>
                </StyledPredictionTableHeaderItem>
                <StyledPredictionTableHeaderItem>
                  <p>2</p>
                </StyledPredictionTableHeaderItem>
                <StyledPredictionTableHeaderItem>
                  <p>1X</p>
                </StyledPredictionTableHeaderItem>
                <StyledPredictionTableHeaderItem>
                  <p>12</p>
                </StyledPredictionTableHeaderItem>
                <StyledPredictionTableHeaderItem>
                  <p>X2</p>
                </StyledPredictionTableHeaderItem>
              </StyledPredictionTableHeaderRight>
            </StyledPredictionTableHeader>
            {isLoading || isRefetching ? (
              <p>Loading...</p>
            ) : (
              predictions?.slice(0, shownPredictions).map((item, _idx) => (
                <StyledTableBodyContainer key={_idx}>
                  <StyledPredictionTableBodyLeft>
                    <p className="time-stamp">
                      {moment(item?.start_date).format("Do-MM-YYYY")}
                    </p>
                    <StyledPredictionGamePlayersContainer>
                      <p>{item?.home_team}</p>
                      <p>{item?.away_team}</p>
                    </StyledPredictionGamePlayersContainer>
                  </StyledPredictionTableBodyLeft>
                  <StyledPredictionTableHeaderRight>
                    <StyledPredictionTableBodyItem className="tip">
                      <p>{item?.prediction}</p>
                    </StyledPredictionTableBodyItem>
                    <StyledPredictionTableBodyItem className="one">
                      <p>{roundUpToOneDecimal(item?.odds?.[1] ?? 0)}</p>
                    </StyledPredictionTableBodyItem>
                    <StyledPredictionTableBodyItem className="x">
                      <p>{roundUpToOneDecimal(item?.odds?.X ?? 0)}</p>
                    </StyledPredictionTableBodyItem>
                    <StyledPredictionTableBodyItem className="two">
                      <p>{roundUpToOneDecimal(item?.odds?.[2] ?? 0)}</p>
                    </StyledPredictionTableBodyItem>
                    <StyledPredictionTableBodyItem className="onex">
                      <p>{roundUpToOneDecimal(item?.odds?.["1X"] ?? 0)}</p>
                    </StyledPredictionTableBodyItem>
                    <StyledPredictionTableBodyItem className="onetwo">
                      <p>{roundUpToOneDecimal(item?.odds?.[12] ?? 0)}</p>
                    </StyledPredictionTableBodyItem>
                    <StyledPredictionTableBodyItem className="twox">
                      <p>{roundUpToOneDecimal(item?.odds?.X2 ?? 0)}</p>
                    </StyledPredictionTableBodyItem>
                  </StyledPredictionTableHeaderRight>
                </StyledTableBodyContainer>
              ))
            )}
          </StyledPredictionTableContainer>
          {predictions?.length > shownPredictions ? (
            <StyledViewMore onClick={redirectToPredictionsPage}>
              <p>View More</p>
            </StyledViewMore>
          ) : null}
        </StyledFlexItem>
        <StyledFlexItem>
          <StyledFlexItemheader>
            <h1>Betting Codes</h1>
            {access_token?.access_token !== "" && (
              <div>
                <Button
                  green
                  label="Create Code"
                  onClick={handleOpenCreateBettingCodeModal}
                />
              </div>
            )}
          </StyledFlexItemheader>
          <StyledCodeTableContainer>
            {isLoadingBettingCodes ? (
              <PageLoader />
            ) : bettingCodes?.length <= 0 ? (
              <EmptyState />
            ) : (
              bettingCodes?.slice(0, 5)?.map((item, i) => (
                <StyledCodeItem key={i}>
                  <StyledCodeLeft>
                    <StyledCodeLogo
                      src={item?.company?.logo_url}
                      alt={item?.company?.name}
                    />
                  </StyledCodeLeft>
                  <StyledCodeRight>
                    <StyledCodePredictionCode>
                      <div className="main">
                        <p>{item?.code}</p>
                        <div className="share-button">
                          <FaShareAlt />
                        </div>
                      </div>
                    </StyledCodePredictionCode>
                    <StyledCodeRatingContainer>
                      <StyledCodeRatingItem>
                        <StyledCodeRatingActionContainer
                          onClick={() =>
                            vote({ id: item?.id, vote_type: "UPVOTE" })
                          }
                          active={
                            clicked?.id === item?.id &&
                            clicked.vote_type === "UPVOTE"
                              ? "true"
                              : "false"
                          }
                          type={clicked?.vote_type}
                        >
                          {clicked?.vote_type === "UPVOTE" &&
                          isVoting &&
                          clicked?.id === item?.id ? (
                            <LoadingSpinner />
                          ) : (
                            <FaThumbsUp />
                          )}
                        </StyledCodeRatingActionContainer>
                        <StyledCodeRatingTotal>
                          <p>{item?.upVotes}</p>
                        </StyledCodeRatingTotal>
                      </StyledCodeRatingItem>
                      <StyledCodeRatingItem>
                        <StyledCodeRatingActionContainer
                          onClick={() =>
                            vote({ id: item?.id, vote_type: "DOWNVOTE" })
                          }
                          type={clicked?.vote_type}
                        >
                          {clicked?.vote_type === "DOWNVOTE" &&
                          isVoting &&
                          clicked?.id === item?.id ? (
                            <LoadingSpinner />
                          ) : (
                            <FaThumbsDown />
                          )}
                        </StyledCodeRatingActionContainer>
                        <StyledCodeRatingTotal>
                          <p>{item?.downVotes}</p>
                        </StyledCodeRatingTotal>
                      </StyledCodeRatingItem>
                    </StyledCodeRatingContainer>
                  </StyledCodeRight>
                </StyledCodeItem>
              ))
            )}
          </StyledCodeTableContainer>

          {bettingCodes?.length > 5 ? (
            <StyledViewMore onClick={() => navigate("/betting-codes")}>
              <p>View More</p>
            </StyledViewMore>
          ) : null}
        </StyledFlexItem>
      </StyledFlex>

      <StyledNewsSection>
        <StyledNewsSectionHeader>
          <StyledFLexHeaderNavigationItemLink
            to="/news?type=news"
            isactive="true"
          >
            News
          </StyledFLexHeaderNavigationItemLink>
          <StyledFLexHeaderNavigationItemLink
            to="/news?type=features"
            isactive="false"
          >
            Features
          </StyledFLexHeaderNavigationItemLink>
          <StyledFLexHeaderNavigationItemLink
            to="/news?type=latest"
            isactive="false"
          >
            Latest
          </StyledFLexHeaderNavigationItemLink>
        </StyledNewsSectionHeader>
        {isLoadingNews ? (
          <PageLoader />
        ) : news?.length <= 0 ? (
          <EmptyState />
        ) : (
          <StyledNewsContent>
            {news?.slice(0, 8)?.map((news, _idx) => (
              <div className={`div${_idx + 1}`} key={_idx}>
                <Newsbanner data={news} />
              </div>
            ))}
          </StyledNewsContent>
        )}
        {news?.length > 5 ? (
          // <StyledViewMoreButton>
          //   <a href="/news">View More</a>
          // </StyledViewMoreButton>

          <button
          className={`
              text-white text-[.8rem] px-[.5rem] py-[.5rem] bg-[#ff0000] rounded-md
           ml-3`}
         
        > <a href="/news">Load More  News</a>
        
        </button>
        ) : null}
      </StyledNewsSection>

      <CreateBettingCodeModal
        isOpen={createBettingCodeModalIsOpen}
        onClose={close}
      />
    </div>
  );
};
