import { devices } from "@/utils";
import styled from "styled-components";

export const StyledSubscriptionPageContainer = styled.main`
  width: 100%;
`;
export const StyledSubscriptionTitle = styled.h1`
  text-align: center;
  font-size: 2rem;
  color: ${({ theme }) => theme.palette.red};
  margin: 2rem 0px;
`;

export const StyledSubscriptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  @media ${devices.laptop} {
    flex-direction: column;
  }
`;

export const StyledSubscriptionItem = styled.div`
  width: 32%;
  h2 {
    font-size: 1.4rem;
    text-align: center;
    margin-bottom: 1rem;
  }
`;

export const StyledSubscriptionItemWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.container};
  border-radius: ${({ theme }) => theme.borderRadiusSM};
  padding: 1rem;
`;

export const StyledSubscriptionGamesContainer = styled.div`
  :first-of-type {
    margin-bottom: 1rem;
  }
`;

export const StyledSubscriptionGamesContainerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem;
  background-color: ${({ theme }) => theme.palette.container_light};
  border-top-right-radius: ${({ theme }) => theme.borderRadiusSM};
  border-top-left-radius: ${({ theme }) => theme.borderRadiusSM};
`;

export const StyledSubscriptionGames = styled.div<{ withBg: string }>`
  padding: 0.6rem;
  background-color: ${({ theme, withBg }) =>
    withBg === "true" ? theme.palette.background : "transparent"};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadiusSM};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadiusSM};
  p {
    margin: 0.3rem 0px;
    font-size: 0.9rem;
  }
`;
