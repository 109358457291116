import { Logo } from "@/assets";
import {
  FaFacebookF,
  FaInstagram,
  FaTelegram,
  FaTwitter,
} from "react-icons/fa";
import {
  StyledFooterContainer,
  StyledFooterIcon,
  StyledFooterIconsContainer,
  StyledFooterWrapper,
  StyledLinkItem,
} from "./footer.styles";

export const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <StyledFooterWrapper>
      <StyledFooterContainer>
        <img src={Logo} alt="fansweek" />
        <p>
          <StyledLinkItem to="/">About Us</StyledLinkItem> |
          <StyledLinkItem to="/">Contact Us</StyledLinkItem> |
          <StyledLinkItem to="/">Terms of Service</StyledLinkItem> |
          <StyledLinkItem to="/">Privacy Policy</StyledLinkItem> |
          <StyledLinkItem to="/">Careers</StyledLinkItem> |
          <StyledLinkItem to="/">Fansweek App</StyledLinkItem> |
          <StyledLinkItem to="/">Advertisement</StyledLinkItem>
        </p>
        <StyledFooterIconsContainer>
          <StyledFooterIcon href="https://www.facebook.com/fansweekofficial/" target="_blank" rel="noopener noreferrer">
            <FaFacebookF size={20} />
          </StyledFooterIcon>
          <StyledFooterIcon>
            <FaTwitter size={20} />
          </StyledFooterIcon>
          <StyledFooterIcon href="https://www.instagram.com/fansweekofficial/" target="_blank" rel="noopener noreferrer">
            <FaInstagram size={20} />
          </StyledFooterIcon>
          <StyledFooterIcon>
            <FaTelegram size={20} />
          </StyledFooterIcon>
        </StyledFooterIconsContainer>
        <p>© {year} Fansweek - All rights reserved.</p>
      </StyledFooterContainer>
    </StyledFooterWrapper>
  );
};
