import {
  EmptyState,
  Newsbanner,
  PageLoader,
  SectionHeader,
} from "@/components";
import { useGetNews } from "@/network/hooks";
import { useSearchParams } from "react-router-dom";
import {
  StyledFLexHeaderNavigationItemLink,
  StyledNewsContainer,
  StyledNewsContent,
  StyledNewsSectionHeader,
  StyledViewMoreButton,
} from "../Home/home.styles";

export const NewsPage = () => {
  const [searchParams] = useSearchParams();
  const news_type = searchParams.get("type");
  const { news, isLoading, loadMore, isRefetching } = useGetNews();

  return (
    <StyledNewsContainer>
      <SectionHeader title="Top Stories" />
      <div style={{ marginTop: "30px" }}>
        <StyledNewsSectionHeader>
          {["news", "features", "latest"].map((type) => (
            <StyledFLexHeaderNavigationItemLink
              to={`/news?type=${type.toLocaleLowerCase()}`}
              isactive={news_type === type ? "true" : "false"}
            >
              {type}
            </StyledFLexHeaderNavigationItemLink>
          ))}
        </StyledNewsSectionHeader>
      </div>
      {isLoading ? (
        <PageLoader />
      ) : news?.length <= 0 ? (
        <EmptyState />
      ) : (
        <StyledNewsContent>
          {news?.slice(0, 8)?.map((news, _idx) => (
            <div className={`div${_idx + 1}`}>
              <Newsbanner data={news} />
            </div>
          ))}
        </StyledNewsContent>
      )}
      {news?.length > 5 ? (
        <StyledViewMoreButton>
          <p>View More</p>
        </StyledViewMoreButton>
      ) : null}
    </StyledNewsContainer>
  );
};
