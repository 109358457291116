import { devices } from "@/utils";
import styled from "styled-components";
export const LoginModalContainer = styled.div`
  width: 100%;
  height: 100%;

  h1 {
    margin-bottom: 2rem;
  }
`;

export const StyledRedirectAuth = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 2rem;
  @media ${devices.mobile} {
    font-size: 9px;
  }
  span > span {
    color: ${({ theme }) => theme.palette.primary};
    cursor: pointer;
  }
`;

export const StyledAuthModalBottom = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
 
`;

export const StyledErrorText = styled.li`
  font-size: .7rem;
 color: red;
 
`;
