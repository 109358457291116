import { Button } from "@/components";
import styled from "styled-components";

export const StyledNewsDetailsPageContainer = styled.div`
  padding: 0 0.2rem;
  
  h1 {
    margin-bottom: 0.6rem;
    font-weight: 700;
    font-size: 1.3rem;
  }

  .posted_on {
    font-size: 0.9rem;
    color: ${({ theme }) => theme.palette.grey};
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    
    img {
      border-radius: 100%;
      width: 2.5rem;
      object-fit: cover;
      height: 2.5rem;
      margin-right: 0.5rem;
    }
  }

  img {
    display: block;
    width: 100%;
    margin: 1rem 0px;
  }

  .news_sub_title {
    font-size: 1.3rem;
    margin-bottom: 2rem;
  }

  .description {
    font-size: 0.9rem;
    line-height: 1.5rem; /* Increased line-height for double spacing */
  }
`;

export const StyledNewsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
`;

export const StyledCommentsContainer = styled.div`
  width: 100%;
  margin: 1.5rem 0px;
  background: ${({ theme }) => theme.palette.container};
  padding: 1rem;
  border-radius: ${({ theme }) => theme.borderRadiusSM};
`;

export const StyledAuthUserCommentContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin: 2rem 0px;
  
  img {
    border-radius: 100%;
    width: 2.5rem;
    object-fit: cover;
    height: 2.5rem;
  }
`;

export const StyledAuthUserCommentForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
  width: 100%;
  
  input {
    width: 100%;
    border: none;
    padding: 1.2rem;
    color: black;
    border-radius: ${({ theme }) => theme.borderRadiusSM};
  }
`;

export const CommentButton = styled(Button)`
  padding: 0.6rem;
  width: 6rem;
  text-transform: uppercase;
`;

export const StyledCommentsCards = styled.div`
  padding: 0.6rem;
  display: flex;
  gap: 1rem;
  align-items: center;

  img {
    border-radius: 100%;
    width: 2.5rem;
    object-fit: cover;
    height: 2.5rem;
  }

  h4 {
    margin-top: 1rem;
    font-weight: 500;
  }

  p {
    font-size: 0.8rem;
  }
`;
