import styled from "styled-components";

export const StyledInputContainer = styled.div<{
  error?: string;
  disabled?: boolean;
}>`
  width: 100%;
  margin-bottom: 1rem;
  p,
  small {
    font-size: 14px;
  }

  p {
    margin-bottom: 0.3rem;
  }

  small {
    margin-top: 0.6rem;
    color: ${({ theme }) => theme.palette.red};
  }

  input,
  select {
    display: block;
    width: 100%;
    outline: none;
    background-color: transparent;
    border: 1px solid
      ${({ theme, error }) =>
        error ? theme.palette.red : theme.palette.container_light};
    padding: 1rem;
    border-radius: ${({ theme }) => theme.borderRadiusSM};
  }
`;
