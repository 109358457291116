import styled from "styled-components";

export const StyledLiveScorePageWrapper = styled.div`
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
`;

export const StyledLeftLiveScoreContainer = styled.div`
  width: 12%;
  h4 {
    margin-top: 1.3rem;
    margin-bottom: 0.7rem;
  }
`;

export const StyledRightLiveScoreContainer = styled.div`
  width: 12%;
`;

export const StyledLiveScoreMainContent = styled.main`
  width: 75%;
`;

export const StyledLeftTournamentItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: 0.4rem;

  img {
    width: 1rem;
    height: 1rem;
  }

  p {
    font-size: 0.8rem;
  }
`;

export const StyledLeftCountry = styled.div`
  width: 100%;

  p {
    font-size: 0.8rem;
    margin-bottom: 0.6rem;
  }
`;

export const StyledLiveScoreSectionContainer = styled.div`
  width: 100%;
  padding: 1rem;
  margin-top: 2rem;
  background-color: ${({ theme }) => theme.palette.container};
`;

export const StyledSectionLeagueHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1.3rem;
  img {
    width: 2rem;
    object-fit: contain;
  }
  p {
    font-weight: bold;
    font-size: 0.9rem;
  }
`;

export const StyledLeagueGamesTable = styled.div`
  width: 100%;
`;

export const StyledSectionTable = styled.div`
  width: 100%;
  padding: 1rem;
  border-radius: ${({ theme }) => theme.borderRadiusSM};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  :nth-of-type(even) {
    background-color: ${({ theme }) => theme.palette.container_light};
  }
`;

export const StyledSectionTableLeft = styled.div`
  width: 10%;
  .text_wrapper {
    display: flex;
    align-items: center;
    gap: 0.6rem;
    div {
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 100px;
      background-color: ${({ theme }) => theme.palette.green["500"]};
    }
  }
`;

export const StyledSectionTableCenter = styled.div`
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  .club {
    display: flex;
    align-items: center;
    gap: 0.7rem;
  }
`;

export const StyledSectionTableRight = styled.div`
  width: 10%;
`;
