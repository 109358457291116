// openAuthModal
import { createSlice } from "@reduxjs/toolkit";

interface IApp {
  authModalIsOpen: boolean;
}

const userInitState: IApp = {
  authModalIsOpen: false,
};

export const appSlice = createSlice({
  name: "user",
  initialState: userInitState,
  reducers: {
    openAuthModal: (state) => {
      return { ...state, authModalIsOpen: true };
    },
    closeAuthModal: (state) => {
      return { ...state, authModalIsOpen: false };
    },
  },
});

export const { openAuthModal, closeAuthModal } = appSlice.actions;
export default appSlice.reducer;
