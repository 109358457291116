import { HomeLayout } from "@/layout";
import {
  BettingCodePage, HomePage, LiveScorePage,
  NewsDetailsPage,
  NewsPage,
  PredictionPage,
  SubscriptionPage
} from "@/pages";
import { BrowserRouter, Route, Routes } from "react-router-dom";

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeLayout />}>
          <Route path="/" index element={<HomePage />} />
          <Route path="/betting-codes" element={<BettingCodePage />} />
          <Route path="/predictions" element={<PredictionPage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/news/:slug" element={<NewsDetailsPage />} />
          <Route path="/live-score" element={<LiveScorePage />} />
          <Route path="/subscription" element={<SubscriptionPage />} />
          <Route path="*" element={<h1>Page Not Found</h1>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
