import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledSubheaderContainer = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  margin-bottom: 2rem;
  background-color: ${({ theme }) => theme.palette.background};
`;

export const StyledGameTypeHeaderContaner = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.container};
`;

export const StyledSportsContainer = styled.div`
  width: 75%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.4rem;
  padding: 0.7rem 0px;
`;

export const StyledSportItem = styled(Link)<{ isactive: string }>`
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontSize};
  position: relative;
  transition: 0.3s ease;
  font-size: 0.9rem;
  &::before {
    transition: 0.3s ease;
    height: 0.1rem;
    background-color: red;
    border-radius: ${({ theme }) => theme.borderRadiusSM};
    position: absolute;
    content: "";
    bottom: -0.4rem;
    left: 0;
    width: ${({ isactive }) => (isactive === "true" ? "100%" : 0)};
  }
`;

export const StyledDatesContainer = styled.div`
  width: 75%;
  margin: 0 auto;
  border-bottom: 2px solid ${({ theme }) => theme.palette.red};
  padding: 1rem 0px 0.4rem 0px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const StyledDateItem = styled.div<{ isactive: string }>`
  font-size: 0.8rem;
  position: relative;
  cursor: pointer;
  text-transform: capitalize;
  font-weight: ${({ isactive }) => (isactive === "true" ? "bold" : "")};
  &::before {
    transition: 0.3s ease;
    /* height: 1rem;
    width: 1rem; */
    border-right: 0.5rem solid red;
    border-top: 0.5rem solid ${({ theme }) => theme.palette.background};
    position: absolute;
    content: "";
    bottom: -0.75rem;
    left: 50%;
    visibility: ${({ isactive }) =>
      isactive === "true" ? "visible" : "hidden"};
    transform: translateX(-50%) rotate(-135deg);
  }
`;
