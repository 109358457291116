import { Button } from "@/components/Button";
import { Input } from "@/components/Input";
import { Modal } from "@/components/Modal";
import {
  LoginModalContainer,
  StyledAuthModalBottom,
  StyledRedirectAuth,
} from "./auth.styles";

export const ForgotPasswordModal = ({
  isOpen,
  onClose,
  onOpenLoginModal,
  onOpenResetPasswordModal,
}: {
  isOpen: boolean;
  onClose: () => void;
  onOpenLoginModal: () => void;
  onOpenResetPasswordModal: () => void;
}) => {
  const handleOpenLoginModal = () => {
    onClose();
    onOpenLoginModal();
  };
  const handleOpenResetPasswordModal = () => {
    onClose();
    onOpenResetPasswordModal();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <LoginModalContainer>
        <h1>Forgot Password</h1>
        <Input label="Email" placeholder="Enter your Email address" />
        <Button
          label="Continue"
          isLoading={false}
          onClick={handleOpenResetPasswordModal}
        />
        <StyledAuthModalBottom>
          <StyledRedirectAuth></StyledRedirectAuth>
          <StyledRedirectAuth>
            <span>
              Remember account password?{" "}
              <span onClick={handleOpenLoginModal}>Login</span>
            </span>
          </StyledRedirectAuth>
        </StyledAuthModalBottom>
      </LoginModalContainer>
    </Modal>
  );
};
