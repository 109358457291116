import { signin } from "@/app/store";
import { Button } from "@/components/Button";
import { Input } from "@/components/Input";
import { Modal } from "@/components/Modal";
import { useAppDispatch } from "@/hooks";
import { useLogin } from "@/network/hooks";
import { useSelector } from "react-redux";
import {
  LoginModalContainer,
  StyledAuthModalBottom,
  StyledRedirectAuth,
} from "./auth.styles";

export const LoginModal = ({
  isOpen,
  onClose,
  onOpenForgotPasswordModal,
  onOpenCreateAccountModal,
}: {
  isOpen: boolean;
  onClose: () => void;
  onOpenForgotPasswordModal: () => void;
  onOpenCreateAccountModal: () => void;
}) => {
  const handleOpenForgotPasswordModal = () => {
    onClose();
    onOpenForgotPasswordModal();
  };
  const handleOpenCreateAccountModal = () => {
    onClose();
    onOpenCreateAccountModal();
  };

  const {
    dirty,
    errors,
    handleChange,

    isValid,
    values,
  } = useLogin({ handleCloseModal: onClose });
  const dispatch = useAppDispatch();
  const loading = useSelector((state: any) => state.user.loading);

  const submit = async () => {
    await dispatch(signin(values));

    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <LoginModalContainer>
        <h1>Login</h1>
        <Input
          label="Email"
          placeholder="Enter your Email address"
          type="email"
          name="email"
          value={values.email}
          onChange={handleChange}
          error={errors?.email}
        />
        <Input
          label="password"
          placeholder="Enter your password"
          type="password"
          name="password"
          value={values.password}
          onChange={handleChange}
          error={errors?.password}
        />
        <Button
          label="Login"
          type="submit"
          isLoading={loading}
          disabled={!isValid || !dirty}
          onClick={() => submit()}
        />
        <StyledAuthModalBottom>
          <StyledRedirectAuth>
            <span>
              <span onClick={handleOpenForgotPasswordModal}>
                Forgot Password?
              </span>
            </span>
          </StyledRedirectAuth>
          <StyledRedirectAuth>
            <span>
              Don't have an account?{" "}
              <span onClick={handleOpenCreateAccountModal}>Create Account</span>
            </span>
          </StyledRedirectAuth>
        </StyledAuthModalBottom>
      </LoginModalContainer>
    </Modal>
  );
};
