import {
  StyledEmptyStateContainer,
  StyledEmptyStateContent,
} from "./styles.emptystate";

export const EmptyState = () => {
  return (
    <StyledEmptyStateContainer>
      <StyledEmptyStateContent>
        <p className="title">Unable to get result at the moment.</p>
        <p className="sub">Please Try Again Later.</p>
      </StyledEmptyStateContent>
    </StyledEmptyStateContainer>
  );
};
