import { Newsbanner } from "@/components";
import { PageLoader } from "@/components/Spinner";
import { useGetSingleNews } from "@/network/hooks";
import { useState } from "react";
import {
  CommentButton,
  StyledAuthUserCommentContainer,
  StyledAuthUserCommentForm,
  StyledCommentsCards,
  StyledCommentsContainer,
  StyledNewsDetailsPageContainer,
  StyledNewsGrid,
} from "./news.styles";

export const NewsDetailsPage = () => {
  const { news, isLoading } = useGetSingleNews();
  const [value, setValue] = useState({ comment: "" });

  const handleChange = (e: any) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  // Handle missing or incomplete data gracefully
  const newsTitle = news?.title || "No Title";
  const newsAuthor = news?.author?.first_name || "Unknown Author";
  const newsPublishedAt = news?.published_at || "Unknown Date";
  const newsImage = news?.featured_image || "";
  const authorImage = news?.author?.avatar || "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGfqj4Vp-HNsPfOw10I7G_eJOckxXZA3ytAg&s";
  const newsBody = news?.body
    ? news.body.split("\r\n\r\n").map((paragraph: string, index: number) => (
        <p key={index} style={{ marginBottom: "1.5em" }}>
          {paragraph}
        </p>
      ))
    : "No Content";

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <StyledNewsDetailsPageContainer>
          <h1>{newsTitle}</h1>
          {/* <StyledAuthUserCommentContainer>
            <img
              src={authorImage}
              alt={newsAuthor}
              style={{ borderRadius: "50%", width: "40px", height: "40px", marginRight: "10px" }}
            />
            <p className="posted_on">
              {newsAuthor} | {newsPublishedAt}
            </p>
          </StyledAuthUserCommentContainer> */}
          {newsImage && <img src={newsImage} alt={newsTitle} />}
          <StyledAuthUserCommentContainer>
            <img
              src={authorImage}
              alt={newsAuthor}
              style={{ borderRadius: "50%", width: "40px", height: "40px", marginRight: "10px" }}
            />
            <p className="posted_on">
              {newsAuthor} | {newsPublishedAt}
            </p>
          </StyledAuthUserCommentContainer>
          <div>{newsBody}</div>
          {/* comments */}
          <StyledCommentsContainer>
            <h3>0 Comments</h3>
            {value.comment !== "" && (
              <StyledCommentsCards>
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGfqj4Vp-HNsPfOw10I7G_eJOckxXZA3ytAg&s"
                  alt="User"
                />
                <div>
                  <h4>Username</h4>
                  <p>{value.comment}</p>
                </div>
              </StyledCommentsCards>
            )}
            <StyledAuthUserCommentContainer>
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGfqj4Vp-HNsPfOw10I7G_eJOckxXZA3ytAg&s"
                alt="User"
              />
              <StyledAuthUserCommentForm>
                <input
                  type="text"
                  name="comment"
                  style={{ color: "black" }}
                  value={value.comment}
                  placeholder="Join the discussion"
                  onChange={handleChange}
                />
                <div>
                  <CommentButton label="send" />
                </div>
              </StyledAuthUserCommentForm>
            </StyledAuthUserCommentContainer>
          </StyledCommentsContainer>
          {/* more news */}
          <StyledNewsGrid>
            <Newsbanner />
            <Newsbanner />
            <Newsbanner />
            <Newsbanner />
          </StyledNewsGrid>
        </StyledNewsDetailsPageContainer>
      )}
    </>
  );
};
