import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useState } from "react";
import toast from "react-hot-toast";
import * as yup from "yup";
import { httpClient, useHttpPrivate } from "../api";
import {
  BaseError,
  BettingCode,
  BettingCompany,
  Endpoints,
  GetAllBettingCodesResponse,
  GetAllBettingCompaniesResponse,
  ICreateBettingCodeDTO,
  ICreateBettingCodeResponse,
  QueryKeys,
  VoteReponse
} from "../interface";

export const useCreateBettingCode = ({
  handleCloseModal,
}: {
  handleCloseModal?: () => void;
}) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    async (values: ICreateBettingCodeDTO) => {
      const res = await httpClient.post<ICreateBettingCodeResponse>(
        Endpoints.CREATE_BETTING_CODE,
        { ...values, odds: 2 }
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
   
        toast.success(data?.message);
        if(data){

          queryClient.invalidateQueries([QueryKeys.GET_ALL_BETTING_CODES]);
        }
        handleCloseModal?.();
      },
      onError: (error: BaseError) => {
        const message = error?.response?.data?.message;
        const code_error = error?.response?.data?.errors["code"][0];
        const company_id_error = error?.response?.data?.errors["company_id"][0];
        const end_date_error = error?.response?.data?.errors["end_date"][0];
        setFieldError("company_id", company_id_error);
        setFieldError("end_date", end_date_error);
        setFieldError("code", code_error);
        toast.error(message);
      },
    }
  );

  const onSubmit = (values: ICreateBettingCodeDTO) => {
    mutate(values);
  };

  const { bettingCompanies, isLoading: isLoadingBettingCompanies } =
    useGetBettingCompanies();

  const bettingCompaniesOptions =
    bettingCompanies?.map((com) => ({
      key: com.name,
      value: com.id,
    })) ?? [];

  const initialValues: ICreateBettingCodeDTO = {
    code: "",
    company_id: "",
    end_date: "",
  };

  const schema = yup.object().shape({
    code: yup.string().required("Please provide betting code!."),
    company_id: yup.string().required("Please select company!."),
    end_date: yup.string().required("Please provide betting code end date!."),
  });

  const {
    handleChange,
    dirty,
    errors,
    isValid,
    handleSubmit,
    values,
    setFieldError,
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: schema,
  });

  return {
    isLoading,
    handleChange,
    dirty,
    errors,
    isValid,
    handleSubmit,
    values,
    bettingCompaniesOptions,
    isLoadingBettingCompanies,
  };
};

export const useGetAllBettingCodes = () => {
  const [bettingCodes, setBettingCodes] = useState<Array<BettingCode>>([]);

  const { isLoading, refetch, isRefetching } = useQuery(
    [QueryKeys.GET_ALL_BETTING_CODES],
    async () => {
      const { data } = await httpClient.get<GetAllBettingCodesResponse>(
        `${Endpoints.GET_ALL_BETTING_CODES}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
       
        setBettingCodes(data?.data);
      },
      onError: (error: BaseError) => {
        const message = error?.response?.data?.message;
        console.log(message);
        
      },
    }
  );

  const loadMore = () => {
    refetch();
  };

  return {
    isLoading,
    isRefetching,
    bettingCodes,
    loadMore,
  };
};

export const useGetBettingCompanies = () => {
  const [bettingCompanies, setBettingCompanies] = useState<
    Array<BettingCompany>
  >([]);

  const { isLoading, refetch, isRefetching } = useQuery(
    [QueryKeys.GET_ALL_BETTING_COMPANIES],
    async () => {
      const { data } = await httpClient.get<GetAllBettingCompaniesResponse>(
        `${Endpoints.GET_ALL_BETTING_COMPANIES}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        
        setBettingCompanies(data?.data);
      },
      onError: (error: BaseError) => {
        const message = error?.response?.data?.message;
        console.log(message);
      },
    }
  );

  const loadMore = () => {
    refetch();
  };

  return { isLoading, isRefetching, bettingCompanies, loadMore };
};

export type VoteType = "UPVOTE" | "DOWNVOTE";

export const useVoteBettingCode = () => {
  const [clicked, setClicked] = useState<{
    id: number;
    vote_type: VoteType;
  } | null>(null);
  const httpPrivate = useHttpPrivate();
  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(
    async ({ id, vote_type }: { id: number; vote_type: VoteType }) => {
      const { data } = await httpPrivate.put<VoteReponse>(
        `${Endpoints.GET_ALL_BETTING_CODES}/${id}/${
          vote_type === "UPVOTE"
            ? "upvote"
            : vote_type === "DOWNVOTE"
            ? "downvote"
            : ""
        }`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([QueryKeys.GET_ALL_BETTING_CODES]);
        toast.success(data?.message);
      },
      onError: (error: BaseError) => {
        const message = error?.response?.data?.message;
        toast.error(message);
      },
    }
  );

  const vote = ({ id, vote_type }: { id: number; vote_type: VoteType }) => {
    setClicked({ id, vote_type });
    mutate({ id, vote_type });
  };

  return { vote, isLoading, clicked };
};
