import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import styled from "styled-components";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  /* background-color: #fff; */
  padding: 2rem;
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
  min-width: 40%;
  background-color: ${({ theme }) => theme.palette.container};
`;

const CloseModalButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: ${({ theme }) => theme.borderRadiusSM};
  background-color: ${({ theme }) => theme.palette.primary};
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  shouldClose?: boolean;
}

export const Modal = ({
  isOpen,
  onClose,
  children,
  shouldClose = true,
}: IProps) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        {children}
        {shouldClose ? (
          <CloseModalButton onClick={onClose}>
            <AiOutlineClose />
          </CloseModalButton>
        ) : null}
      </ModalContent>
    </ModalOverlay>
  );
};
