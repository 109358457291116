import { BarcaLogo, TournamentIcon } from "@/assets";
import { SectionHeader } from "@/components";
import {
  StyledLeagueGamesTable,
  StyledLeftCountry,
  StyledLeftLiveScoreContainer,
  StyledLeftTournamentItem,
  StyledLiveScoreMainContent,
  StyledLiveScorePageWrapper,
  StyledLiveScoreSectionContainer,
  StyledRightLiveScoreContainer,
  StyledSectionLeagueHeader,
  StyledSectionTable,
  StyledSectionTableCenter,
  StyledSectionTableLeft,
  StyledSectionTableRight,
} from "./livescore.styles";
import React from "react";

export const LiveScorePage = () => {
  return (
    <StyledLiveScorePageWrapper>
      <StyledLeftLiveScoreContainer>
        <h4>Top Tournaments</h4>
        {[1, 2, 3, 4, 5].map((item) => (
          <StyledLeftTournamentItem key={item}>
            <img src={TournamentIcon} alt="icon" />
            <p>NPFL</p>
          </StyledLeftTournamentItem>
        ))}

        <h4>Countries</h4>
        <StyledLeftCountry>
          <p>Albania</p>
          <p>Algeria</p>
          <p>Andorra</p>
          <p>Angola</p>
          <p>Argentina</p>
          <p>Armenia</p>
          <p>Aruba</p>
          <p>Australia</p>
          <p>Austria</p>
          <p>Azerbaijan</p>
          <p>Bahrain</p>
          <p>Bangladesh</p>
          <p>Belarus</p>
          <p>Belgium</p>
          <p>Benin</p>
          <p>Bermuda</p>
          <p>Bolivia</p>
          <p>Bosnia and Herzegovina</p>
          <p>Botswana</p>
          <p>Brazil</p>
          <p>Bulgaria</p>
          <p>Burkina Faso</p>
          <p>Burundi</p>
          <p>Cambodia</p>
          <p>Cameroon</p>
          <p>Canada</p>
          <p>Cape Verde</p>
          <p>Chile</p>
          <p>China</p>
          <p>Colombia</p>
          <p>Costa Rica</p>
          <p>Croatia</p>
          <p>Cuba</p>
          <p>Curaçao</p>
          <p>Cyprus</p>
          <p>Czech Republic</p>
          <p>Denmark</p>
          <p>Djibouti</p>
          <p>Dominican Republic</p>
          <p>DR Congo</p>
          <p>Ecuador</p>
          <p>Egypt</p>
          <p>El Salvador</p>
          <p>England</p>
          <p>Estonia</p>
          <p>Eswatini</p>
          <p>Ethiopia</p>
          <p>Faroe Islands</p>
          <p>Fiji</p>
          <p>Finland</p>
          <p>France</p>
          <p>Gabon</p>
          <p>Gambia</p>
          <p>Georgia</p>
          <p>Germany</p>
          <p>Ghana</p>
          <p>Gibraltar</p>
          <p>Greece</p>
        </StyledLeftCountry>
      </StyledLeftLiveScoreContainer>
      <StyledLiveScoreMainContent>
        <SectionHeader
          title="Live Football Scores"
          style={{ marginTop: "0.1rem" }}
        />

        {[1, 2, 3, 4].map((item) => (
          <React.Fragment key={item}>
            <StyledLiveScoreSectionContainer>
              <StyledSectionLeagueHeader>
                <img src={BarcaLogo} alt="club" />
                <p>England - Premier League</p>
              </StyledSectionLeagueHeader>
              <StyledLeagueGamesTable>
                <StyledSectionTable>
                  <StyledSectionTableLeft>
                    <p>FT</p>
                  </StyledSectionTableLeft>
                  <StyledSectionTableCenter>
                    <div className="club">
                      <p>Liverpool FC</p>
                      <img src={BarcaLogo} alt="club" />
                      <p>0</p>
                    </div>
                    <span>-</span>
                    <div className="club">
                      <p>0</p>
                      <img src={BarcaLogo} alt="club" />
                      <p>Liverpool FC</p>
                    </div>
                  </StyledSectionTableCenter>
                  <StyledSectionTableRight></StyledSectionTableRight>
                </StyledSectionTable>
                <StyledSectionTable>
                  <StyledSectionTableLeft>
                    <p>FT</p>
                  </StyledSectionTableLeft>
                  <StyledSectionTableCenter>
                    <div className="club">
                      <p>Liverpool FC</p>
                      <img src={BarcaLogo} alt="club" />
                      <p>0</p>
                    </div>
                    <span>-</span>
                    <div className="club">
                      <p>0</p>
                      <img src={BarcaLogo} alt="club" />
                      <p>Liverpool FC</p>
                    </div>
                  </StyledSectionTableCenter>
                  <StyledSectionTableRight></StyledSectionTableRight>
                </StyledSectionTable>
                <StyledSectionTable>
                  <StyledSectionTableLeft>
                    <div className='text_wrapper'>
                      48 <div />
                    </div>
                  </StyledSectionTableLeft>
                  <StyledSectionTableCenter>
                    <div className="club">
                      <p>Liverpool FC</p>
                      <img src={BarcaLogo} alt="club" />
                      <p>0</p>
                    </div>
                    <span>-</span>
                    <div className="club">
                      <p>0</p>
                      <img src={BarcaLogo} alt="club" />
                      <p>Liverpool FC</p>
                    </div>
                  </StyledSectionTableCenter>
                  <StyledSectionTableRight></StyledSectionTableRight>
                </StyledSectionTable>
                <StyledSectionTable>
                  <StyledSectionTableLeft>
                    <p>20:33</p>
                  </StyledSectionTableLeft>
                  <StyledSectionTableCenter>
                    <div className="club">
                      <p>Liverpool FC</p>
                      <img src={BarcaLogo} alt="club" />
                      <p>0</p>
                    </div>
                    <span>-</span>
                    <div className="club">
                      <p>0</p>
                      <img src={BarcaLogo} alt="club" />
                      <p>Liverpool FC</p>
                    </div>
                  </StyledSectionTableCenter>
                  <StyledSectionTableRight></StyledSectionTableRight>
                </StyledSectionTable>
              </StyledLeagueGamesTable>
            </StyledLiveScoreSectionContainer>
          </React.Fragment>
        ))}
      </StyledLiveScoreMainContent>
      <StyledRightLiveScoreContainer></StyledRightLiveScoreContainer>
    </StyledLiveScorePageWrapper>
  );
};
