import React from "react";
import styled from "styled-components";
import LoadingSpinner from "../Spinner";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  label?: string;
  green?: boolean;
}

const StyledButton = styled.button<{ disabled?: boolean; green?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  background: ${({ theme, green }) =>
    green ? theme.palette.green[500] : theme.palette.primary};
  color: ${({ theme }) => theme.palette.text};
  border: none;
  width: 100%;
  padding: .5rem 1rem;
  border-radius: ${({ theme }) => theme.borderRadiusSM};
`;

export const Button = ({ isLoading, label, ...props }: IProps) => {
  return (
    <StyledButton {...props}>
      {isLoading ? <LoadingSpinner /> : label}
    </StyledButton>
  );
};
