import { Button } from "@/components/Button";
import { Input } from "@/components/Input";
import { Modal } from "@/components/Modal";
import { LoginModalContainer } from "./auth.styles";

export const ResetAccountPasswordModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} shouldClose={false}>
      <LoginModalContainer>
        <h1>Reset Password</h1>
        <Input label="New Password" placeholder="Enter new Password" />
        <Input
          label="Confirm New Password"
          placeholder="Re-Enter new password"
        />
        <Button label="Reset Account" isLoading={false} />
      </LoginModalContainer>
    </Modal>
  );
};
