// import React from "react";
// import { StyledInputContainer } from "./input.styles";

// interface IProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
//   error?: string;
//   label?: string;
//   options?: { key: string; value: string | number }[];
// }

// export const Select = ({ error, label, options, ...props }: IProps) => {
//   return (
//     <StyledInputContainer error={error} className={props.className}>
//       <p>{label}</p>
//       <select {...props}>
//         <option>{props.placeholder}</option>
//         {options?.map((opt) => (
//           <option value={opt.value}>{opt.key}</option>
//         ))}
//       </select>
//       <small>{error}</small>
//     </StyledInputContainer>
//   );
// };



import React from "react";
import { StyledInputContainer } from "./input.styles";

interface IProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  error?: string;
  label?: string;
  options?: { key: string; value: string | number }[];
  placeholder?: string; // Added placeholder property
}

export const Select = ({ error, label, options, placeholder, ...props }: IProps) => {
  return (
    <StyledInputContainer error={error} className={props.className}>
      <p>{label}</p>
      <select {...props}>
        {placeholder && <option value="">{placeholder}</option>} {/* Handle placeholder separately */}
        {options?.map((opt) => (
          <option key={opt.value} value={opt.value}>{opt.key}</option>
        ))}
      </select>
      <small>{error}</small>
    </StyledInputContainer>
  );
};
