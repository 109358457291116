import styled from "styled-components";

export const StyledPredictionPageContainer = styled.main`
  width: 100%;
  margin: 0 auto;
  min-height: 100%;
`;

export const StyledPredictionItem = styled.div`
  padding: 1rem;
  background-color: ${({ theme }) => theme.palette.container};
  margin-top: 1rem;
  border-radius: ${({ theme }) => theme.borderRadiusSM};
  .rating {
    width: 40%;
  }
`;
