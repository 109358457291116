import { CoinsBG, LivePlayers } from "@/assets";
import { devices } from "@/utils";
import styled from "styled-components";

export const StyledLayoutWrapper = styled.div`
  width: 100%;
  position: relative;
  @media screen and (min-width: 1800px) {
    width: 75%;
    margin: 0 auto;
  }
`;
export const StyledLayoutMainContainer = styled.main`
  // width: 98%;
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const StyledLayoutLeftContent = styled.div`
  width: 16%;
  height: 100vh;
  position: sticky;
  top: 0;
  @media ${devices.laptop} {
    display: none;
  }
`;

export const StyledLayoutRightContent = styled.div`
  width: 16%;
  height: 100vh;
  position: sticky;
  top: 0;
  @media ${devices.laptop} {
    display: none;
  }
`;

export const StyledLayoutContentContainer = styled.main<{ isHome?: string }>`
  width: ${({ isHome }) => (isHome === "true" ? "65%" : "100%")};
  margin: 0 auto;
  min-height: 95vh;
  @media ${devices.laptop} {
    width: 100%;
  }
`;

export const StyledLayoutCoinsContainer = styled.div`
  background-image: url(${CoinsBG});
  height: 80vh;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1rem;
  border-radius: ${({ theme }) => theme.borderRadiusSM};
  h1 {
    font-size: 3rem;
  }
  p {
    font-size: 14px;
  }
`;

export const StyledTopMatchContainer = styled.div`
  width: 100%;
  padding: 1rem;
  border-radius: ${({ theme }) => theme.borderRadiusSM};
  background-color: ${({ theme }) => theme.palette.container};
  margin-top: 2rem;

  h1 {
    font-size: 1.5rem;
    margin: 0.7rem 0px;
  }
`;

export const StyledTopMatchHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  p {
    font-size: 12px;
  }
`;

export const StyledVersusContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledVersusItem = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    width: 3rem;
  }

  p {
    font-size: 10px;
    margin: 0.3rem 0px;
  }

  h3 {
    font-size: 16px;
  }

  &.right {
    align-items: flex-end;
  }
`;

export const StyledLoadMoreButton = styled.button`
  border: none;
  background: ${({ theme }) => theme.palette.container_light};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.7rem;
  margin-top: 1rem;
  border-radius: ${({ theme }) => theme.borderRadiusXS};
`;

export const StyledVersusText = styled.p`
  width: 20%;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;

export const StyledLivePlayersContainer = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  background-image: url(${LivePlayers});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 80vh;
  border-radius: ${({ theme }) => theme.borderRadiusSM};

  h1 {
    font-size: 3rem;
    text-align: center;
  }

  h3 {
    font-size: 1.2rem;
    text-align: center;
  }
`;
