import { Button } from "@/components/Button";
import { Input } from "@/components/Input";
import { StyledInputContainer } from "@/components/Input/input.styles";
import { Select } from "@/components/Input/Select";
import { Modal } from "@/components/Modal";
import { useCreateBettingCode } from "@/network/hooks";
import { BettingCodeModalContainer, StyledBettingCodeBottom } from "./styles";

export const CreateBettingCodeModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const {
    dirty,
    errors,
    handleChange,
    handleSubmit,
    isLoading,
    isValid,
    values,
    bettingCompaniesOptions,
    isLoadingBettingCompanies,
  } = useCreateBettingCode({ handleCloseModal: onClose });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <BettingCodeModalContainer>
        <h1>Create Code</h1>
        <Input
          placeholder="Insert Code Here"
          type="text"
          name="code"
          value={values.code}
          onChange={handleChange}
          error={errors?.code}
          className="centered"
        />
        <Select
          name="company_id"
          value={values.company_id}
          onChange={handleChange}
          error={errors?.company_id}
          options={bettingCompaniesOptions}
          className="centered"
          disabled={
            bettingCompaniesOptions.length <= 0 || isLoadingBettingCompanies
          }
        />

        <StyledInputContainer>
          <p>End Date</p>
          <input
            placeholder="Select Date"
            type="date"
            name="end_date"
            value={values.end_date}
            onChange={handleChange}
            // error={errors?.end_date}
          />
        </StyledInputContainer>
        <StyledBettingCodeBottom>
          <div>
            <Button
              label="Create"
              isLoading={isLoading}
              disabled={!isValid || !dirty}
              onClick={() => handleSubmit()}
              green
            />
          </div>
        </StyledBettingCodeBottom>
      </BettingCodeModalContainer>
    </Modal>
  );
};
