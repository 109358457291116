import { Banner1 } from "@/assets";
import { devices } from "@/utils";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledBannerContainer = styled.div`
  width: 100%;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: url(${Banner1});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: ${({ theme }) => theme.borderRadiusSM};

   @media ${devices.tablet} {
    height: 25vh; /* Adjust height for tablet devices */
  }

  @media ${devices.mobile} {
    height: 13vh; /* Adjust height for mobile devices */
  }
`;

export const StyledBannerLeftContainer = styled.div`
  width: 40%;
  @media ${devices.tablet} {
    width: 0;
  }
`;

export const StyledBannerRightContainer = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3rem;
  h1 {
    font-size: 1.7rem;
  }
  
  @media ${devices.tablet} {
    width: 100%;
    padding: 0 1.2rem;
    

  }
  @media ${devices.laptop} {
    width: 60%;
    padding: 0 1.2rem;
    h1 {
      font-size: 1rem;
    }
  }
  @media ${devices.mobile} {
    width: 100%;
    h1 {
      font-size: 1rem;
    }
  }
`;

export const StyledBannerRightContent = styled.div`
  display: flex;
  align-items: center;

  justify-content: space-between;
  width: 60%;
  @media ${devices.mobile} {

    width: 70%;
  }
  @media ${devices.tablet} {
    width: 70%;
  }
`;

export const StyledBannerRightContentItem = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  &.right {
    align-items: flex-end;
  }
  img {
    width: 3rem;
    margin: 0.4rem 0px;
  }
  p {
    font-size: 0.8rem;
  }
  @media ${devices.tablet} {
    width: 90%;
  }
`;

export const StyledBannerRightContentScore = styled.div`
  width: 30%;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 100;
  @media ${devices.tablet} {
    font-size: .8rem;
  }
`;

export const StyledFlex = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 2rem;
  margin: 1rem 0px;
  @media ${devices.tablet} {
    flex-direction: column;
  }
`;

export const StyledFlexItem = styled.div`
  padding: 1rem;
  width: 50%;
  border-radius: ${({ theme }) => theme.borderRadiusSM};
  background-color: ${({ theme }) => theme.palette.container};
  @media ${devices.tablet} {
    width: 100%;
    padding: 0.6rem;
  }
`;

export const StyledFlexItemheader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    font-size: 1.2rem;
    font-weight: 400;
  }
`;

export const StyledFlexHeaderNavigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

export const StyledFLexHeaderNavigationItemLink = styled(Link)<{
  isactive: string;
}>`
  font-size: 1rem;
  position: relative;
  cursor: pointer;
  text-transform: capitalize;
  &::before {
    transition: 0.3s ease;
    height: 0.1rem;
    background-color: red;
    border-radius: ${({ theme }) => theme.borderRadiusSM};
    position: absolute;
    content: "";
    bottom: -0.4rem;
    left: 0;
    width: ${({ isactive }) => (isactive === "true" ? "100%" : 0)};
  }
`;

export const StyledFlexheaderNavigationItem = styled.p<{
  isactive: string;
}>`
  font-size: 1rem;
  position: relative;
  cursor: pointer;
  &::before {
    transition: 0.3s ease;
    height: 0.1rem;
    background-color: red;
    border-radius: ${({ theme }) => theme.borderRadiusSM};
    position: absolute;
    content: "";
    bottom: -0.4rem;
    left: 0;
    width: ${({ isactive }) => (isactive === "true" ? "100%" : 0)};
  }
`;

export const StyledViewMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  p {
    cursor: pointer;
    font-size: 14px;
  }
`;

export const StyledNewsSection = styled.div`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadiusSM};
  background-color: ${({ theme }) => theme.palette.container};
  padding: 1rem;
`;

export const StyledNewsSectionHeader = styled.div`
  padding: 0px 1rem;
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
`;

export const StyledNewsContainer = styled.div`
  /* width: 90%;
  margin: 0 auto; */
`;

// export const StyledNewsContent = styled.div`
//   display: grid;
//   grid-template-columns: repeat(12, 1fr);
//   grid-template-rows: repeat(12, 10fr);
//   grid-column-gap: 1rem;
//   grid-row-gap: 1rem;

//   .div1 {
//     grid-area: 1 / 1 / 7 / 7;
//   }
//   .div2 {
//     grid-area: 7 / 1 / 13 / 7;
//   }
//   .div3 {
//     grid-area: 1 / 7 / 5 / 10;
//   }
//   .div4 {
//     grid-area: 1 / 10 / 5 / 13;
//   }
//   .div5 {
//     grid-area: 5 / 7 / 9 / 10;
//   }
//   .div6 {
//     grid-area: 5 / 10 / 9 / 13;
//   }
//   .div7 {
//     grid-area: 9 / 7 / 13 / 10;
//   }
//   .div8 {
//     grid-area: 9 / 10 / 13 / 13;
//   }

//   @media ${devices.tablet} {
//     .div1 {
//       grid-area: 1/ 1/ 13 / 13;
//     }
//     .div2 {
//       grid-area: 7/ 1/ 13 / 13;
//     }
//     .div3 {
//       display: none;
//     }
//     .div4 {
//       display: none;
//     }
//     .div5 {
//       display: none;
//     }
//     .div6 {
//       display: none;
//     }
//     .div7 {
//       display: none;
//     }
//     .div8 {
//       display: none;
//     }
//   }
// `;



// export const StyledNewsContent = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   gap: 1rem;

//   .div1, .div2 {
//     flex: 1 1 calc(50% - 1rem); /* Two columns */
//   }

//   .div3, .div4, .div5, .div6, .div7, .div8 {
//     flex: 1 1 calc(25% - 1rem); /* Four columns */
//   }

//   @media ${devices.tablet} {
//     flex-direction: column;
//     .div1, .div2, .div3, .div4, .div5, .div6, .div7, .div8 {
//       flex: 1 1 100%; /* Full width on tablet */
//     }
//   }

//   @media ${devices.mobile} {
//     flex-direction: column;
//     .div1, .div2, .div3, .div4, .div5, .div6, .div7, .div8 {
//       flex: 1 1 100%; /* Full width on mobile */
//     }
//   }
// `;


export const StyledNewsContent = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 10fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  .div1 {
    grid-area: 1 / 1 / 7 / 7;
  }
  .div2 {
    grid-area: 6 / 1 / 13 / 7;
  }
  // .div3 {
  //   grid-area: 10 / 1 / 15/ 7;
  // }
     .div3 {
    grid-area: 1 / 7 / 5 / 10;
  }
  .div4 {
    grid-area: 1 / 10 / 5 / 13;
  }
  .div5 {
    grid-area: 5 / 7 / 9 / 10;
  }
  .div6 {
    grid-area: 5 / 10 / 9 / 13;
  }
  .div7 {
    grid-area: 9 / 7 / 13 / 10;
  }
  .div8 {
    grid-area: 9 / 10 / 13 / 13;
  }

  @media ${devices.tablet} {
    .div1 {
      grid-area: 1 / 1 / 6 / 13;
    }
    .div2 {
      grid-area: 6 / 1 / 11 / 13;
    }
    .div3 {
      grid-area: 11 / 1 / 16 / 13;
      display: block;
    }
    .div4 {
      grid-area: 16 / 1 / 21 / 13;
      display: block;
    }
    .div5 {
      grid-area: 21 / 1 / 26 / 13;
      display: block;
    }
    .div6 {
      grid-area: 26 / 1 / 31 / 13;
      display: block;
    }
    .div7 {
      grid-area: 31 / 1 / 36 / 13;
      display: block;
    }
    .div8 {
      grid-area: 36 / 1 / 41 / 13;
      display: block;
    }
  }

  @media ${devices.mobile} {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .div1,
    .div2,
    .div3,
    .div4,
    .div5,
    .div6,
    .div7,
    .div8 {
      width: 100%;
    }
  }
`;


export const StyledViewMoreButton = styled.button`
  border: none;
  // width: 8rem;
  cursor: pointer;
  padding: 0.8rem 1.4rem;
  border-radius: ${({ theme }) => theme.borderRadiusSM};
  background-color: ${({ theme }) => theme.palette.container_light};
`;

export const StyledPredictionTableHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.4rem 0px;
  /* padding: 0.3rem 0.5rem; */
`;

export const StyledTableBodyContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.4rem 0px;
  padding: 0.3rem 0.5rem;
  border-radius: ${({ theme }) => theme.borderRadiusXS};
  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.palette.container_light};
  }
  @media ${devices.tablet} {
    padding: 0.3rem;
  }
`;

export const StyledPredictionTableHeaderLeft = styled.div`
  width: 48%;
  display: flex;
  align-items: center;
  height: 10px;
  @media ${devices.tablet} {
    width: 35%;
  }
`;

export const StyledPredictionTableBodyLeft = styled.div`
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .time-stamp {
    font-size: 0.8rem;
    font-weight: bold;
    flex: 0.25;
    @media ${devices.tablet} {
      font-size: 0.6rem;
    }
  }
  @media ${devices.tablet} {
    width: 35%;
  }
`;

export const StyledPredictionTableHeaderRight = styled.div`
  width: 53%;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  justify-content: space-between;
  @media ${devices.tablet} {
    width: 65%;
  }
`;

export const StyledPredictionTableBodyRight = styled.div`
  width: 53%;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  justify-content: space-between;
  @media ${devices.tablet} {
    width: 65%;
  }
`;

export const StyledPredictionTableHeaderItem = styled.div`
  display: flex;
  align-items: center;
  width: 1.9rem;
  /* background-color: red; */
  justify-content: center;

  p {
    font-size: 0.8rem;
    font-weight: bold;
  }
`;

export const StyledPredictionTableContainer = styled.div`
  width: 100%;
`;

export const StyledPredictionGamePlayersContainer = styled.div`
  flex: 0.75;
  p {
    font-size: 0.85rem;
    margin-bottom: 0.3rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  @media ${devices.tablet} {
    p {
      font-size: 0.7rem;
    }
  }
`;

export const StyledPredictionTableBodyItem = styled.div`
  display: flex;
  align-items: center;
  width: 2rem;
  height: 2rem;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadiusXS};

  @media ${devices.tablet} {
    p {
      font-size: 0.6rem;
    }
  }

  p {
    font-size: 0.8rem;
  }

  &.tip {
    background-color: ${({ theme }) => theme.palette.red};
  }

  &.one {
    background-color: ${({ theme }) => theme.palette.green["900"]};
  }

  &.x {
    background-color: ${({ theme }) => theme.palette.green["500"]};
  }

  &.two {
    background-color: ${({ theme }) => theme.palette.green["100"]};
  }

  &.onex {
    background-color: ${({ theme }) => theme.palette.yellow["900"]};
  }

  &.onetwo {
    background-color: ${({ theme }) => theme.palette.yellow["500"]};
  }

  &.twox {
    background-color: ${({ theme }) => theme.palette.yellow["100"]};
  }
`;

export const StyledCodeTableContainer = styled.div`
  width: 100%;
  margin-top: 0.7rem;
`;

export const StyledCodeItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 0.5rem;
  border-radius: ${({ theme }) => theme.borderRadiusXS};
  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.palette.container_light};
  }
`;

export const StyledCodeLeft = styled.div`
  width: 20%;
`;

export const StyledCodeRight = styled.div`
  width: 78%;
  
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledCodePredictionCode = styled.div`
  width: 45%;
  display: flex;
  align-items: center;
  gap: 20px;
  
  .main {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    font-size: 1.2rem;
    font-weight: bold;
  }

  p.small {
    font-size: 0.8rem;
    font-weight: 400;
  }
svg{
  font-size: .7rem;
}
  .share-button {
    background-color: ${({ theme }) => theme.palette.black};
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: .5rem;
  }
`;

export const StyledCodeRatingContainer = styled.div`
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.6rem;
  svg{
    font-size: .7rem;
  }
`;

export const StyledCodeRatingItem = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
`;

export const StyledCodeLogo = styled.img`
  width: 100%;
  height: 2rem;
  object-fit: cover;
`;

export const StyledCodeRatingActionContainer = styled.div<{
  active?: string;
  type?: "UPVOTE" | "DOWNVOTE";
}>`
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  cursor: pointer;
  border-top-left-radius: ${({ theme }) => theme.borderRadiusXS};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadiusXS};
  background-color: ${({ theme, active, type }) =>
    active === "true" && type === "UPVOTE"
      ? theme.palette.green[500]
      : active === "true" && type === "DOWNVOTE"
      ? theme.palette.red
      : theme.palette.grey};
`;

export const StyledCodeRatingTotal = styled.div`
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  border-top-right-radius: ${({ theme }) => theme.borderRadiusXS};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadiusXS};
  background-color: ${({ theme }) => theme.palette.background};
  p {
    font-size: 0.7rem;
  }
`;
